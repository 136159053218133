export default {
	common: {
		award1: "Melhor Contrato para Corretor de Diferenças da Ásia em 2023",
		award2: "2022 Melhor Broker Global",
		award3: "Corretor Mais Confiável em 2022",
		award4: "A marca mais confiável de 2021",
		award5: "Melhor corretor de tecnologia financeira",
		award6: "O corretor mais transparente para transações",
		country: "análise",
		health: "saúde",
		analysis: "país",
		information: "informação",
		help: "ajuda",
		confirm: "Confirmar",
		cancel: "Cancelar",
		tip: "Prompt",
		place_select: "Seleccione por favor",
		placeEnter: "Por favor, introduza",
		countryCode: "Código do país",
		submit: "Enviar",
		more: "Mais",
		deposit: "Depósito",
		real_trade: "Operações reais",
		paper_trade: "Operações simuladas",
		build_paperTrade: "Criação de uma conta simulada",
		start_trade: "Comece a negociar imediatamente",
		app_name: "FPMarkets",
		success: "Sucesso！",
		dataSources_name: "Trading Central",
		reset: "Repor",
		iknow: "Entendido",
		noData: "Actualmente, não existem dados disponíveis",
		modify: "Modificar",
		pingcang: "Cierre de posiciones",
		all: "Inteiro",
		tip_cardMain: "Tire ou carregue uma foto da FRONT do seu bilhete de identidade",
		tip_cardBack: "Tire ou carregue uma foto do BAIXO do seu bilhete de identidade",
		tip_cardMain1: "Tire ou carregue uma foto da FRONT da sua carteira de motorista",
		tip_cardBack1: "Tire ou carregue uma foto do FUNDO da sua carteira de motorista",
		tip_cardMain2: "Tire ou carregue uma foto do seu passaporte",
		tip_cardBack2: "Tire ou carregue uma foto do seu passaporte",
	},
	currency: {
		btcusdt: "Bitcoin",
		ethusdt: "Terras do Éter",
		ltcusdt: "Litecoin",
		xrpusdt: "Ripple",
		adausdt: "Moeda Ada",
		eosusdt: "Moeda de Pomelo",
		dotusdt: "Boca Raton (anteriormente conhecida como Boca Raton), moeda russa",
		trxusdt: "Wave farmland coin (computação)",
		xmrusdt: "Moeda de Monroe (por exemplo, dólar americano)",
		dogeusdt: "Moeda para cãozinho",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "Dólar australiano Dólar americano",
		fx_sgbpusd: "Libra-dólar",
		fx_seurusd: "Eurodólar",
		fx_snzdusd: "Dólar da Nova Zelândia (NZD) Dólar dos Estados Unidos",
		fx_susdcad: "Dólar americano-Dólar canadiano",
		fx_susdchf: "Dólar americano Franco suíço",
		fx_susdjpy: "Dólar americano-iene",
		fx_saudcad: "Dólar australiano Dólar canadiano",
		fx_saudchf: "Dólar australiano e franco suíço",
		fx_saudjpy: "Dólar australiano Iene japonês",
		fx_saudnzd: "Dólar australiano Dólar neozelandês (NZD)",
		fx_scadchf: "Dólar canadiano Franco suíço",
		fx_scadjpy: "Dólar canadiano Iene japonês",
		fx_schfjpy: "Franco suíço iene",
		fx_seuraud: "Euro Dólar australiano",
		fx_seurcad: "Euro Dólar canadiano",
		fx_seurgbp: "Euro Libra esterlina",
		fx_seurjpy: "Euro Iene",
		fx_seurnzd: "Euro Dólar da Nova Zelândia (NZD)",
		fx_sgbpaud: "Libra esterlina Dólar australiano",
		fx_sgbpchf: "Libra esterlina Franco suíço",
		fx_sgbpjpy: "GBPJPY",
		fx_sgbpnzd: "Libra Dólar da Nova Zelândia (NZD)",
		fx_scadnzd: "Dólar canadiano Dólar neozelandês",
		hf_CL: "Petróleo bruto WTI Nova Iorque",
		hf_OIL: "Petróleo bruto Brent",
		hf_GC: "Ouro de Nova Iorque",
		hf_SI: "Prata de Nova Iorque",
		hf_HG: "Cobre (palavra emprestada)",
		hf_NG: "Estados Unidos Gás natural",
		hf_CAD: "Cobre (palavra emprestada)",
		hf_AHD: "Alumínio de Londres",
		HX_AAPL: "Romã",
		HX_BABA: "Ali Babá, personagem de As Noites da Arábia",
		HX_GOOG: "Google",
		HX_WMT: "Wal-Mart, Walmart (retalhista)",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Nikola Tesla (1856-1943), inventor e engenheiro sérvio",
		HX_N225: "Nikkei 225",
		HX_GDAXI: "DAX30, Alemanha",
		HX_IBEX: "IBEX35, Espanha",
		HX_SPX: "Índice S&P 500",
		HX_FTSE: "FTSE 100",
		HX_KS11: "KOSPI Coreia",
		HX_NZ50: "Nova Zelândia 50",
		HX_PSI20: "Portugal PSI20",
		hf_ZSD: "Zinco de Londres",
		hf_NID: "Níquel de Londres",
		hf_PBD: "Chumbo de Londres",
		hf_SND: "Lata de Londres",
		hf_XAU: "Ouro de Londres (Ouro à vista)",
		hf_XAG: "Prata de Londres (Prata à vista)",
		hf_XPT: "Futuros de platina",
		hf_XPD: "Futuros de paládio",
		hf_CT: "Algodão americano",
		hf_SM: "Soja em pó",
		hf_BO: "Óleo de soja dos EUA",
		HX_SENSEX: "SENSEX, Mumbai, Índia",
		HX_KLSE: "FTSE Malásia KLCI",
		HX_KSE100: "Karachi, Paquistão",
		HX_FCHI: "CAC40, França",
		HX_SX5E: "Snooker europeu 50",
		HX_ICEXI: "ICEX, Islândia",
		HX_ASE: "ASE Atenas, Grécia",
		HX_OMXC20: "OMX Copenhaga 20",
		HX_OSEBX: "OSEBX, Noruega",
		HX_OMXSPI: "OMXSPI, Suécia",
		HX_AMD: "Chaowei Semiconductor",
		HX_DIS: "Disney (nome)",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "MasterCard (marca)",
		HX_NVDA: "NVIDIA, empresa de placas gráficas para computadores",
		HX_INTC: "Intel",
		HX_GOGO: "Gogo Inc",
		HX_STX: "Tecnologia Seagate",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "AstroCoin",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
	currenctType: {
        title: "Selecione a moeda",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Dólar americano',
        currenctType15: 'Dólar de Hong Kong',
        currenctType16: 'Novo dólar taiwanês',
        currenctType17: 'Dólar malaio',
        currenctType18: 'Nova moeda',
        currenctType19: 'Iene japonês (unidade monetária)',
        currenctType20: 'Euro (moeda)',
        currenctType21: 'Dólar australiano',
        currenctType22: 'Rupia indonésia',
        currenctType23: 'Libra esterlina',
        currenctType24: 'Baht tailandês',
        currenctType25: 'Dólar canadense',
        currenctType26: 'Rupia vietnamita',
        currenctType27: 'Won sul-coreano (unidade monetária)',
        currenctType28: 'Dólar de Macau',
    },
    addnew2: {
        address: {
            title: "Endereço de retirada",
            account_number: "Número da conta",
            add_btn: "Adicionar endereço",
            label_type: "Selecione o tipo",
            place_type: "Selecione o tipo",
            label_number: "Número da conta",
            place_number: "Digite o número de sua conta",
            wallet_address: 'Endereço da carteira',
            bank_name: 'Nome do banco',
            bank_address: "Endereço do banco",
            name: "Nome e sobrenome",
            payee_address: 'Endereço do beneficiário',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nome da filial",
            branch_code: "Código de linha",
            bank_code: "Código do banco",
            phone: "Número de telefone celular",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Digite o conteúdo",
        },
    },
	addnew: {
		paypassTit: "Alterar a senha de retirada",
		paypassTit1: "Por favor, defina a sua senha de retirada",
		paypassTit2: "Inicializar a sua palavra-passe para a sua palavra-passe de início de sessão",
		loan: "Empréstimo",
		repaid: "Reembolso",
        unpaid: "Não pago",
		loanMore: "Preciso de um empréstimo.",
		immediateRepayment: "Reembolso imediato",
		accountType1: "Conta de moeda",
		accountType2: "Conta contratual",
		accountType3: "Conta de opção",
		from: "de",
        to: "a",
		get: "Receberá",
		labelBreed: "Moeda",
		placeBreed: "Seleccione por favor uma moeda",
		labelTransAccount: "Conta de transferência",
		labelAmount: "Montante da transferência",
		placeAmount: "Indique o valor da transferência",
		transferTit:"Transferência de conta",
		convertTit:"Moeda de câmbio flash",
		balanceTit:"Saldo da conta",
		available: "Crédito Disponível",
        pending: "Travamento",
        equivalent: "Equivalente",
		coinTrade: "Negociação",
		secondContract: "Segundo",
		number: "Quantidade",
		labelNumber: "Quantidade",
		placeNumber: "Indique a quantidade de resgate",
		time: "Tempo",
        loadAmount: "Montante previsto do empréstimo",
        repaymentCycle: "Ciclo de reembolso do empréstimo",
        dailyRate: "Taxa de juro diária",
        repaymentMethod: "Método de reembolso",
        loanTip: "Empréstimo de crédito (certifique-se de que a imagem está clara e visível)",
        loanTip1: "Enviar o certificado de propriedade",
        loanTip2: "Prova de rendimento (relação de trabalho)",
        loanTip3: "Detalhes do extracto bancário",
        loanTip4: "Carregue uma foto da frente do seu cartão de identificação",
        interest: "Juros",
		repaymentMethod1: "Um reembolso no prazo de vencimento",
	},
	params: {
		product_foreign: "Forex",
		product_shop: "Commodities",
		product_number: "Índices",
		product_stock: "Ações",
		product_coin: "Encriptação",
		billTypes: [
            { v: 101, name: 'Recarregar' },
            { v: 102, name: 'Adicionar' },
            { v: 103, name: 'Congelar' },
            { v: 104, name: 'Descongelamento' },
			{v: 105, name: 'Depósito'},
			{v: 106, name: 'Depósito'},
            { v: 201, name: 'Congelamento de retirada' },
            { v: 202, name: 'Dedução' },
            { v: 203, name: 'Retirada bem sucedida' },
            { v: 204, name: 'A retirada falhou' },
            { v: 205, name: 'Taxa de retirada' },
			{v: 206, name: 'Transferência para fora'},
			{v: 207, name: 'Transferência para'},
			{v: 208, name: 'Transferência de moeda para fora'},
			{v: 209, name: 'Transferência de moeda em'},
            { v: 301, name: 'Dedução do depósito' },
            { v: 302, name: 'Dedução das taxas de tratamento' },
            { v: 303, name: 'Reembolso do depósito' },
            { v: 304, name: 'Lucro' },
            { v: 305, name: 'Perda' },
			{v: 311, name: 'Opções de Compra'},
			{v: 312, name: 'Retorna a opção'},
			{v: 313, name: 'Opção Reembolso'},
			{v: 314, name: 'Taxa de tratamento de opções'},
			{v: 315, name: 'Congelamento das compras monetárias'},
			{v: 316, name: 'Dedução de compra em moeda'},
			{v: 317, name: 'Retorno da Compra de Moedas'},
			{v: 318, name: 'Compra de moedas recebidas'},
			{v: 319, name: 'Compra de moedas recebidas'},
			{v: 320, name: 'Congelamento das vendas monetárias'},
			{v: 321, name: 'Dedução das vendas em moeda estrangeira'},
			{v: 322, name: 'Retorno das vendas de moedas'},
			{v: 323, name: 'Moedas vendidas à conta'},
			{v: 324, name: 'Moedas vendidas à conta'},
			{v: 325, name: 'Taxa de tratamento monetário'},
			{v: 401, name: 'Adição de máquinas de extracção'},
			{v: 402, name: 'Retorno da máquina de mineração'},
			{v: 403, name: 'Receitas de máquinas mineiras'},
			{v: 404, name: 'Saída da máquina mineira'},
			{v: 405, name: 'Taxa de manuseio de saída da máquina de mineração'},
			{v: 411, name: 'Rendimentos de empréstimos'},
			{v: 412, name: 'Taxas de tratamento de empréstimos'},
			{v: 413, name: 'Reembolso bem sucedido'},
        ],
	},
	message: {
		logout: "Sair do login",
		register_success: "o login foi bem sucedido",
		place_inner_personal: "Introduza primeiro as informações pessoais",
		submit_success: "Apresentado com sucesso！",
		copy_success: "Copiado com sucesso！",
		copy_error: "a cópia falhou！",
		modify_success: "Modificado com sucesso",
		no_balance: "Saldo insuficiente para pagar o depósito",
		palce_password: "Introduza a senha",
		trade_success: "Transacção bem sucedida",
		placeEnterAmount: "Indique o montante",
		tipTit1: "Tem a certeza que quer pagar por este pedido",
	},
	web: {
		account: {
			menu1: "Informações da conta",
			menu2: "Os meus bens",
			menu3: "Relatório da Conta",
			menu4: "Segurança da conta",
			menu5: "Aviso",
			menu6: "Centro de Actividades",
			menu7: "A minha carteira",
			menu8: "Cash Out",
			walletAdd: "Adicionar uma nova carteira",
			report: {
				history_tit: "Relatório do Histórico de Transacções",
				stream_tit: "Relatório sobre os fluxos de capital",
				desc: "Selecione o intervalo de datas apropriado para exportar o relatório da conta. (Você pode verificar as informações da conta dentro do ano passado)",
				select_date: "Seleccionar o intervalo de datas",
				week: "Semana passada",
				month: "No mês passado",
				threeMonth: "Nos últimos três meses",
				sixMonth: "Nos últimos seis meses",
				time: "Tempo",
				view_report: "Mostrar o Relatório",
				email_send: "Enviar por e-mail",
				dialog_tit: "Relatório da Conta",
				dialog_p1: "A FPMarkets Holding Ltd está registrada nas Ilhas Cayman (número SIB 1612446) e processa sua transação como objeto da transação.",
				tit2: "Relatório do Histórico de Transacções Mitade",
				tit4: "Relatório de Fluxos de Fundos Mitade",
				tit3: "Informações da conta",
				label1: "Título da conta",
				label2: "ID da conta",
				label3: "Moeda da Conta",
				label4: "Hora do relatório",
				label5: "Período de reporte",
				label6: "Fluxo de capital",
				empty: "Você não tem negócios fechados",
				email_des: "O relatório será enviado para o seu e-mail",
				tab1: "Relatório",
				tab2: "Declaração diária",
				tab3: "Demonstração mensal",
			},
			account: {
				tit1: "Bem-vindos a FPMarkets.",
				tit2: "Verificação de identidade completa para prosseguir com transações reais de depósito",
				p1: "Abrir uma conta de Transacção real",
				p2: "Autenticação",
				btn1: "Autenticação",
				tit3: "Informações pessoais",
				label_name: "Nome",
				label_email: "E-mail",
				label_address: "Endereço residencial",
				tit4: "Informações da conta",
				label_account: "Conta de negociação (Transacção real)",
				label_currency: "Moeda de base (transacções reais)",
				label_company: "Empresa de abertura de contas correntes",
				company_tip: "A FPMarkets Holding Ltd, uma empresa do Grupo FPMarkets, obteve permissão e autorização da Autoridade Monetária das Ilhas Caimão (CIMA).",
				tit5: "Abrir uma conta de Transacção real",
				tit6: "Melhorar a informação",
				tit7: "Preencher as suas informações básicas",
				tit8: "Avaliação dos riscos",
				tit9: "Avaliar a tolerância ao risco de negociação",
				tit10: "Autenticação",
				tit11: "Verificação completa da identidade de acordo com os requisitos regulamentares",
				p3: "Selecione qualquer um dos seguintes documentos comprovativos para verificação",
				type1: "Cartão de identificação",
				type2: "Carta de condução",
				type3: "Passaporte",
				p4: "Confirmo que todas as informações e documentos fornecidos são completos, verdadeiros e precisos. Se houver alguma atualização das informações, prometo fornecer as informações atualizadas à FPMarkets no prazo de 30 dias.",
				next: "Passo seguinte",
				btn2: "Certificado",
				modify_personal_tit: "Modificar informações pessoais",
			},
			activity: {
				tit: "Centro de Actividades",
				desc: "Você pode participar de atividades para receber recompensas e aumentar sua renda de investimento",
			},
			capital: {
				real_trade: "Operações reais",
				label_nowAccount: "Balança corrente",
				label_tradeAccount: "Conta de negociação",
				label_currency: "Moeda de base",
				deposit_withdraw_record: "Registos de depósitos e levantamentos",
				capital_flow_details: "Dados relativos aos fluxos de capital",
			},
			notification: {
				tit: "Configuração das Notificações",
				tit1: "Abrir canais de notificação",
				email: "E-mail",
				message: "Mensagem",
				push: "Empurrar",
				verify: "Validar",
				verified: "Verificado",
				p1: "Por favor, abra / verifique os seguintes canais de notificação, mensagens importantes não são perdidas!",
				type1: "Marketing",
				tit2: "Notificação do Evento de Comercialização",
				desc2: "Informações promocionais da Plataforma, atividades operacionais e outras mensagens平台的优惠信息、运营活动等消息",
				tit3: "Instruções de funcionamento",
				desc3: "Materiais de conhecimento ricos e profissionais",
			},
			security: {
				tit: "Senha",
				isSet: "Definir",
				set: "Para definir",
				verify: "Validar",
				verified: "Verificado",
				tit1: "Verificação da segurança",
				email: "E-mail",
				phone: "Número de telemóvel",
				phone_tit: "Ligação do número de telefone",
				password_tit: "Mudar a senha",
			},
		},
		market: {
			hot: "Popular",
			prev: "última página",
			next: "Página seguinte",
			line: "Partilha do tempo",
			main_subplot: "Indicadores principais e auxiliares de imagem",
			main: "Principais indicadores de imagem",
			subplot: "Indicadores secundários de imagem",
			go_trade: "Ir para a página da Transacção",
		},
		news: {
			tip: "Isenção de responsabilidade: As informações acima são gerais e apenas para referência, e não são usadas como base para decisões de negociação.",
		},
		trade: {
			state1: "Posição de posição",
			state2: "Listagem",
			state3: "História",
			state: "Estado",
			state4: "Posição fechada",
			state5: "Rescindido",
			type: "Tipo",
			type1: "Inteiro",
			type2: "Vender",
			type3: "Comprar",
			left_label1: "Favoritos",
			left_label2: "Visualizações Recentes",
			left_type1: "Modo de Coluna Compacta",
			left_type2: "Modo de coluna solta",
			left_type3: "Mostrar o gráfico de tendências",
			all: "Inteiro",
			right_bp: "Fechar",
			right_sp: "Abrir",
			right_height: "Alto",
			right_low: "Baixo",
			remind_tit: "Novo Lembrete",
			remindList: "Lista de Lembretes",
			remind_btn: "Novo Lembrete",
			right_tab1: "Transacção",
			right_tab2: "Detalhes",
			right_sell: "Vender",
			right_buy: "Comprar",
			right_type: "Tipo",
			right_type1: "Mercado ",
			right_type2: "Pendente ",
			right_priceMoney: "Preço unitário suspenso",
			right_pointerNumber: "Quantidade (mão)",
			right_lever: "Alavanca",
			right_tip: "Depósito exigido",
			right_balance: "Saldo disponível",
			right_stop_profit: "Parar o excedente",
			right_stop_loss: "Parar a perda",
			right_profit_loss: "P/L",
			now_order: "Faça uma encomenda imediatamente",
			confirm_order: "Confirmar a Ordem",
			right_tit1: "Estratégia de negociação",
			right_rinei: "Dentro do dia",
			right_short: "Curto prazo",
			right_middle: "A médio prazo",
			right_time: "Tempo de libertação",
			right_tit2: "Estratégias Alternativas",
			right_tit3: "Revisão técnica",
			right_tit4: "Dados",
			right_tit5: "Sentimento comercial",
			right_label_sell: "Vendedor",
			right_label_buy: "Comprador",
			right_tip2: "Actualizar a cada 15 minutos",
			right_tit6: "Flutuações de preços",
			minute: "Minuto",
			today: "Hoje",
			right_tit7: "Variação de preços",
			now_price: "Preço actual",
			right_low_price: "Preço mais baixo",
			right_height_price: "Preço máximo",
			right_tit8: "Informações sobre contratos",
			right_tit9: "AUD a CHF",
			right_label1: "Número de operações individuais",
			right_label2: "Alavancagem máxima",
			right_label3: "Dimensão do contrato",
			right_label4: "Quantidade máxima total da posição",
			right_label5: "Diferença flutuante de preços",
			right_label6: "Tarifa durante a noite",
			hand: "Mão",
			right_collection_time: "Tempo de recolha",
			right_tip3: "Base da taxa: cobrada a partir de toda a dimensão da transação",
			right_label7: "Nível da margem",
			right_label8: "Manter o rácio de margem",
			right_label9: "Período de negociação",
			right_label10: "Período de negociação actual",
			right_label11: "Próximo período de negociação",


		},
	},
	home: {
		banner_p1: "Adoptar",
		banner_p2: "Transacções na plataforma",
		banner_p3: "Moedas estrangeiras, matérias-primas, índices, acções",
		banner_p4: "E Produtos financeiros mais populares",
		banner_p5: "Você pode explorar os mercados financeiros mais quentes do mundo. A FPMarkets oferece comissão zero, uma diferença de preço baixa altamente vantajosa e configurações flexíveis de alavancagem.",
		hot: "Popular",
		tit1: "Transacções Mais Amigáveis",
		p1: "Interface simples e intuitiva para fácil operação",
		p2: "Suporta páginas da web, aplicativos móveis e desktops, com transações na ponta dos dedos",
		p3: "Ferramentas de gerenciamento de risco, como stop loss/rastreamento stop loss",
		p4: "Vários gráficos técnicos e calendários financeiros, notícias em tempo real",
		p5: "E-mail, SMS e notificações push em tempo real",
		p6: "Melhorando continuamente para alcançar uma melhor experiência de negociação",
		btn1: "Explore a nossa plataforma",
		row3_tit: "Negociação livre a qualquer hora, em qualquer lugar",
		row3_tip: "Suporta páginas da web, aplicativos móveis e desktops, com transações na ponta dos dedos",
		row3_tit2: "Transferência do Código de Digitalização",
		row3_tit3: "Ecrã",
		row3_tit4: "Extremidade móvel",
		regular_tit: "Uma plataforma internacional confiável",
		regular_tip: "Estamos comprometidos em fornecer aos clientes um ambiente de negociação seguro e responsável。",
		regular_tit1: "Autoridade reguladora",
		regular_p1: "Supervisão e autorização autoritativas da indústria, ganhando a confiança de clientes globais",
		regular_tit2: "Protecção da segurança financeira",
		regular_p2: "Depósitos de clientes de varejo são depositados separadamente em contas fiduciárias, conforme exigido por regulamentos regulatórios",
		regular_tit3: "Protecção do saldo negativo",
		regular_p3: "Fornecemos proteção contra saldo negativo para sua conta, e o saldo negativo é liberado para zero em tempo hábil, garantindo que suas perdas não excedam o valor da entrada e negociando com tranquilidade",
		regular_tit4: "5 * 24 horas de serviço ao cliente",
		regular_p4: "Uma equipe de atendimento ao cliente sincera e profissional com suporte on-line 24 horas está disposta a resolver qualquer um dos seus problemas",
		service_tit: "Mais serviços para o ajudar",
		service_tit1: "Estratégia de negociação",
		service_p1: "Estratégias de negociação em tempo real ajudam você a entender as últimas tendências do mercado e melhor aproveitar as oportunidades de negociação.",
		service_tit2: "Conhecimento comercial",
		service_p2: "Aprenda conhecimentos de negociação gratuitamente com FPMarkets para melhorar suas habilidades de negociação.",
		service_tit3: "Gestão de riscos",
		service_p3: "Saiba mais sobre as ferramentas gratuitas de gerenciamento de risco da FPMarkets para proteger melhor seus ativos.",
		step_tit: "Abrir uma conta é simples e conveniente",
		step_tip: "Três passos simples para abrir uma conta em apenas alguns minutos",
		step_tit1: "Registo",
		step_p1: "Preencha as informações e envie sua inscrição",
		step_tit2: "Depósito",
		step_p2: "Várias maneiras de depositar fundos rapidamente",
		step_tit3: "Iniciar a negociação",
		step_p3: "Explore oportunidades de negociação e faça pedidos rapidamente",
		award_tit: "As nossas notas",
		award_p1: "Sempre buscamos excelência e excelência, comprometidos em fornecer aos clientes serviços de negociação de alta qualidade.",
		award_p2: "A marca FPMarkets é muito honrada em receber prêmios de renomadas instituições do setor, reconhecendo os esforços contínuos da equipe e compromisso com os clientes.",
		row7_tit: "Informação financeira global em tempo real",
		row7_tit1: "Notícias em tempo real",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "Carregue para transferir",
		p1: "Negociação a qualquer hora, em qualquer lugar",
		p2: "Operações mais rápidas e inteligentes",
		p3: "0 Transacção de comissão",
		p4: "A maior Variedade de investimentos do mundo",
		renderDom1: "Moeda estrangeira",
		renderDom2: "Acções dos EUA",
		renderDom3: "Ouro",
		renderDom4: "Petróleo bruto",
		renderDom5: "índice",
		renderDom6: "Criptomoeda",
		login: "Login",
		elail: "Caixa de correio",
		phone: "Número de telemóvel",
		no_account: "Sem conta？",
		go_register: "Ir para o registo",
		register: "Registo",
		now_register: "Registe-se Agora",
		have_account: "Conta existente？",
		go_login: "Vai iniciar sessão",
	},
	about: {
		serviceFee: "Taxa de serviço",
		introduction: "Introdução da Plataforma",
		trade_platform: "Plataforma de negociação",
		complaint_process: "Processo de reclamação",
		page: "Página",
		place_search: "Procure o que você quer saber",
		no_answer: "Não foi possível encontrar a resposta que você precisa?",
		contact_us: "Contacte-nos",
		awards: {
			h1: "Recompensas e Honras",
			p1: "Sempre buscamos excelência e excelência, comprometidos em fornecer aos clientes serviços de negociação de alta qualidade. A marca FPMarkets é muito honrada em receber prêmios de renomadas instituições do setor, reconhecendo os esforços contínuos da equipe e compromisso com os clientes.",
			p2: "Ou simplesmente experimente a nossa premiada plataforma de negociação online"
		},
		charges: {
			h1: "Taxas e taxas",
			p1: "Como componente da melhoria da experiência do cliente, a FPMarkets espera fornecer aos clientes serviços de negociação mais econômicos. Estamos empenhados em manter a abertura e transparência nas taxas e taxas, excluindo taxas ocultas. Os principais custos de transação para os clientes são o spread bid-ask e juros overnight sobre suas posições.",
			h2: "Em primeiro lugar, você pode desfrutar do",
			li1: "Cotação do Produto em tempo real",
			li2: "Indicadores técnicos profissionais",
			li3: "Análise exclusiva de mercado",
			free: "Livre",
			view: "Ver em",
			h3: "Spread Bid-Ask",
			p2: "A FPMarkets compensa os seus serviços cobrando a diferença de preço bid-ask. Esta parte do custo foi refletida na cotação do Produto, e quando você estabeleceu uma posição, você realmente pagou a diferença de preço. Como a taxa de diferença de preço não é fixa, sugerimos que você vá para a página de cotação do Produto específico ou faça login em",
			h4: "Taxas de depósito e retirada",
			p3: "A fim de reduzir os custos do cliente, não cobraremos por seu depósito e retirada na maioria dos casos. Mas empresas ou bancos de terceiros podem cobrar taxas de manuseio, como taxas bancárias intermediárias durante seu processo de depósito e retirada",
			p4: "Para situações em que as taxas precisam ser cobradas, você pode verificar com nosso especialista em atendimento ao cliente。",
			p5: "Por exemplo, através de transações internacionais com cartão de crédito, transferências/transferências bancárias ou transações em moedas não suportadas (conversão de moeda estrangeira), etc.",
			h5: "Custo de detenção overnight",
			p6: "Se você ainda manter uma posição às 22h Greenwich Mean Time (GMT 22:00), você será cobrado / depositado uma taxa overnight. Para obter informações sobre as taxas de pernoite para cada Produto, você pode ir para a página de cotação do Produto específico ou fazer login em",
			h6: "Outras despesas",
			p7: "Não há outras taxas. Nossa política de cobrança é completamente transparente, e todos os itens que exigem cobrança serão anunciados e informados com antecedência, sem se preocupar com cobranças implícitas."
		},
		contact: {
			h1: "Contacte-nos",
			address: "Endereço",
			custom_email: "E-mail de contacto do cliente",
			business_email: "E-mail de cooperação empresarial",
			h2: "Escreve-nos uma carta.",
			p1: "Se você tiver alguma dúvida sobre nossos serviços, preencha o seguinte formulário para enviar a pergunta, e nosso especialista em atendimento ao cliente responderá o mais rápido possível. Além disso, se você quiser se registrar para a estratégia de negociação diária fornecida pela nossa colaboração com a Trading Central, selecione 'Subscrever a Análise Diária de Mercado' no Tipo de tabela a seguir e preencha a versão de idioma que você deseja receber. O serviço de assinatura é aplicável apenas a clientes reais da FPMarkets existente.",
			h3: "Processo de recurso",
			p2: "Teremos uma equipe dedicada para investigar e resolver todas as reclamações para você. Consulte os detalhes para referência",
		},
		faq: {
			h1: "Perguntas Frequentes",
			tit1: "Qual é o uso de contas simuladas？",
			tit2: "Como abrir uma conta real？",
			tit3: "Como estabelecer uma lista de preços de mercado？",
			tit4: "Porque não posso fazer um pedido?？",
			p1: "Uma conta simulada e uma conta real são praticamente iguais em termos de interface de transação, dados e operações.A conta simulada contém US $ 50000 em fundos virtuais, com o objetivo de familiarizar os clientes com várias funções da plataforma através de operações simuladas sem qualquer risco financeiro.",
			p2: "Siga os passos abaixo para abrir uma conta real：",
			p3: "1. Entre na página de registro do FPMarkets e siga as instruções: registre e-mail/número de telefone, defina a senha de login e crie automaticamente uma 'conta simulada' após enviar a confirmação.",
			p4: "2.Na página da conta simulada, selecione 'Mudar para Conta Real' e siga o processo para abrir uma 'Conta Real'.",
			p5: "Antes de abrir uma conta real, sugerimos que você leia e entenda os documentos de divulgação legal relacionados à transação.",
			p6: "Observe que você só pode depositar fundos em sua conta real após concluir a verificação de identidade.",
			p7: "Clicando em 'Negociar' irá mostrar todos os Produtos que a plataforma pode oferecer para negociação. Você pode inserir o código da Variedade ou nome na caixa de entrada à direita para pesquisar, selecionar um Produto e clicar em 'Vender/COMPRAR' para abrir uma janela de negociação. Na janela de negociação, você pode ver o preço atual e a margem exigida esperada. Você pode ajustar manualmente o número de negociações abertas, definir um stop profit e stop loss para controle de risco e, em seguida, clique em 'Vender / COMPRAR' abaixo para concluir a abertura. Por favor, note que todos os preços dos Produtos financeiros estão constantemente flutuando e atualizados com o mercado, e o mercado pode ter mudado antes de você pressionar o botão comprar / vender.",
			p8: "Em primeiro lugar, verifique se a conexão de rede é normal. Quando o mercado de Variedades de negociação é temporariamente fechado ou fechado, isso causará a incapacidade de colocar ordens. Para mais informações, entre em contato com nosso serviço de atendimento ao cliente on-line para obter mais assistência.",

		},
		fundSecurity: {
			h1: "Segurança financeira",
			p1: "A FPMarkets é autorizada e regulamentada por agências reguladoras autorizadas. Compliance é o núcleo e a cultura de cada tarefa que realizamos. Sua segurança financeira é crucial, pois todos os depósitos de clientes são segregados e armazenados.",
			p2: "Os depósitos de clientes de retalho são depositados separadamente em contas fiduciárias quando necessário, de acordo com os requisitos regulamentares",
			p3: "A FPMarkets não utiliza fundos de clientes para quaisquer atividades comerciais fora das permissões legais e regulamentares.",
			p4: "A FPMarkets não se envolverá em atividades especulativas em nenhum mercado",
			p5: "Auditoria independente por sociedades de contabilidade terceiras",
		},
		help: {
			h1: "Bem-vindo ao Centro de Ajuda FPMarkets",
		},
		media: {
			h1: "Centro de Mídia",
			p1: "Veja informações, comunicados de imprensa, recursos de mídia, anúncios e outras informações relacionadas à marca FPMarkets.",
		},
		mitrade: {
			h1: "Sobre o FPMarkets",
			view_certificate: "（Método de verificação）",
			p1: "A FPMarkets é uma empresa de tecnologia financeira autorizada e regulamentada por instituições de autoridade, com foco em fornecer aos investidores uma experiência de negociação simples e conveniente. A inovadora plataforma de negociação diversificada FPMarkets ganhou repetidamente prêmios como a melhor plataforma de negociação móvel e a empresa de valores mobiliários mais inovadora.",
			p2: "Através da plataforma FPMarkets, você pode investir e negociar em uma ampla gama de mercados financeiros internacionais, cobrindo centenas de Variedades populares, como ações, índices, commodities, câmbio, etc. Não apenas limitado a plataformas de negociação móveis e web fáceis de usar, a FPMarkets também fornece custos de transação competitivos, execução rápida de transações, excelente atendimento ao cliente e suporte de informações ricas, ajudando você a aproveitar oportunidades de investimento e negociação em tempo hábil.",
			p3: "FPMarkets é estritamente regulamentado por autoridades da indústria e várias instituições. A FPMarkets International é autorizada e regulada pela Comissão de Serviços Financeiros das Maurícias (FSC) com um número de licença GB20025791. Para mais informações sobre a matrícula Maurícia, visite o site oficial do FSC https://www.fscmauritius.org",
			p4: "Faça uma consulta. A MFPMarkets Global possui uma Licença de Serviços Financeiros (AFSL 398528) autorizada pela Comissão Australiana de Valores Mobiliários e Investimentos (ASIC). Para mais detalhes sobre a matrícula australiana, visite o site oficial da ASIC em www.asic.gov.au",
			p5: "Faça uma consulta. A ITrade Holding é autorizada e regulada pela Autoridade Monetária das Ilhas Caimão (CIMA) com um número de licença SIB 1612446. Para mais detalhes sobre a matrícula das Ilhas Caimão, visite o site oficial da CIMA em www.cima.ky",
			p6: "Faça uma consulta. Todas as operações comerciais da FPMarkets são conduzidas sob supervisão estrita e cumprem todos os regulamentos.",
			h2: "Antecedentes do FPMarkets",
			p7: "FPMarkets é uma equipe sênior com rica experiência e conhecimento nas indústrias de negociação financeira e fintech, fundada em Melbourne, Austrália. A filosofia da FPMarkets é tornar as transações mais fáceis e amigáveis",
			p8: "Nossa estratégia integrada e modelo de negócios nos permitem ter uma observação abrangente e aprofundada e compreensão do desenvolvimento da indústria e demanda do mercado, permitindo-nos alocar recursos mais direcionados, inovar continuamente a tecnologia e otimizar a eficiência, e continuamente trazer aos clientes uma experiência de negociação mais conveniente e amigável.",
			h3: "Porquê escolher FPMarkets?",
			why1: "Condições de negociação de limiares baixos",
			why2: "Supervisionado pelas autoridades industriais",
			why3: "Plataforma de negociação simples e intuitiva",
			why4: "Suporte online de alto nível",
			why5: "Custos de Transacção competitivos",
			why6: "Protecção do saldo negativo",
			h4: "Prémios e Honras",
			p9: "Sempre buscamos excelência e excelência, comprometidos em fornecer aos clientes serviços de negociação de alta qualidade.",
			p10: "A marca FPMarkets é muito honrada em receber prêmios de renomadas instituições do setor, reconhecendo os esforços contínuos da equipe e compromisso com os clientes.",
			new_add: "Novos Utilizadores Globais",
			ol1: "Melhor fornecedor de notícias e informações analíticas",
			ol2: "Melhor Empresa de Valores Mobiliários da Ásia",
			ol3: "Australian Foreign Exchange Customer Satisfaction Award",
			ol4: "Melhor Aplicação Móvel",
			ol5: "A empresa de valores mobiliários de crescimento mais rápido da Austrália",
			h5: "Filosofia operacional da FPMarkets",
			tip_tit1: "Responsabilidade",
			tip_tit2: "Simples mas bonito",
			tip_tit3: "Transparente",
			tip_tit4: "Inovar",
			tip_p1: "Todas as transações envolvem riscos, e a FPMarkets está tomando mais medidas para fornecer aos clientes um ambiente de negociação responsável. A FPMarkets está sujeita à autoridade da indústria, supervisão rigorosa de várias instituições e adere a padrões operacionais de alto nível de segurança e estabilidade. Nossa plataforma continua fornecendo várias ferramentas de negociação responsáveis, incluindo gerenciamento de riscos.",
			tip_p2: "A FPMarkets esforça-se por cada detalhe de simplicidade, facilidade de uso e experiência do usuário. Continuamos a otimizar a nossa plataforma e serviços, esperando que todos, sejam investidores iniciantes ou experientes, possam aproveitar facilmente as oportunidades de negociação através da plataforma FPMarkets.",
			tip_p3: "A fim de beneficiar plenamente os nossos clientes de um ambiente comercial justo e eficiente, a FPMarkets segue um sistema de auto-regulação rigoroso e transparente, fornecendo informações abrangentes e transparentes sobre os Produtos da plataforma, ambiente de preços e dados. Todas as taxas são claras e transparentes, garantindo a sinceridade e transparência completas de todo o processo de serviço.",
			tip_p4: "A FPMarkets acredita firmemente que o progresso tecnológico, a inovação financeira e os serviços mais amplos são as forças motrizes para o crescimento, permitindo a criação e partilha contínuas de valor. Por esta razão, incentivamos o pensamento aberto, promovemos continuamente a inovação e pretendemos tornar-nos inovadores e líderes em tecnologia financeira.",
			row6_tit1: "A nossa missão",
			row6_tit2: "A nossa visão",
			row6_tit3: "Oportunidades de emprego",
			row6_p1: "Proporcionar um ambiente comercial justo, eficiente e transparente que não seja limitado por espaço, tempo ou mercado para mais pessoas que queiram realizar transações, liderando a inovação financeira e tecnológica da indústria.",
			row6_p2: "Acreditamos que o progresso tecnológico, a inovação financeira e os serviços diversificados são as forças que impulsionam o nosso crescimento, permitindo-nos criar e partilhar continuamente valor na sociedade.",
			row6_p3: "Para isso, incentivamos o pensamento aberto, promovemos a inovação e pretendemos",
			row6_p4: "Empresa de câmbio líder mundial",
			row6_p5: "Somos uma equipe global que abrange 7 países, e com o rápido desenvolvimento da equipe, temos procurado talentos profissionais para se juntar a nós. Trabalhe com uma equipe multicultural para fazer o que você gosta!",
			row6_p6: "Por favor envie-nos o seu currículo mais recente e as razões pelas quais gostaria de trabalhar connosco:",
			row6_p7: "Além disso, você também pode verificar o",
			row6_p8: "Ou",
			row6_p9: "Confira nossas vagas disponíveis publicamente em.。",
			row6_p10: "A FPMarkets protege estritamente a segurança das suas informações pessoais. Durante o processo de inscrição, a FPMarkets não solicitará informações financeiras de você. O nosso recrutamento não requer qualquer informação financeira, de cartão de crédito, de conta bancária ou qualquer forma de pagamento.",
			row6_p11: "Se você suspeitar de comportamento fraudulento em uma determinada posição, entre em contato",
			row6_p12: "Contacte o departamento de recursos humanos da FPMarkets. Se você confirmar que foi enganado por informações falsas de recrutamento, entre em contato com o departamento institucional relevante local.",

		}
	},
	product: {
		row2: {
			tit: "Variedade comercial",
			sell: "Vender",
			buy: "Comprar",
			trade: "Transacção",
			more_product: "Mais Produtos comerciais",
		},
		row3: {
			tit: "Suportar transacções de vários dispositivos",
			tit1: "Suportar transacções de vários dispositivos",
			p1: "A FPMarkets fornece plataformas de negociação web, móvel e desktop. Você pode trocar a qualquer hora, em qualquer lugar.",
			download: "Download",
		},
		row4: {
			tit: "Seleccionar o Mitrado",
			tit1: "Plataforma simples e intuitiva",
			p1: "Integrando mercado, negociação, gerenciamento de contas, informações e gerenciamento de riscos, ele tem funções poderosas para alcançar uma melhor experiência do usuário",
			tit2: "Custos de Transacção competitivos",
			p2: "0 comissão de transação, altamente competitivo e transparente baixa diferença de preço, baixo interesse overnight, permitindo que você aproveite transações de baixo custo",
			tit3: "Condições de negociação de limiares baixos",
			p3: "O número mínimo de negociações por transação pode ser tão baixo quanto 0,01, e a margem baixa pode ser usada para abrir negociações",
			tit4: "Sob supervisão autorizada",
			p4: "Autorização e supervisão autoritativas na indústria, com todos os depósitos dos clientes sendo segregados e armazenados separadamente para proteger a segurança dos ativos dos clientes",
			tit5: "Protecção do saldo negativo",
			p5: "Sob quaisquer condições de mercado, a perda da sua conta não excederá o valor principal, e os saldos negativos serão compensados em tempo hábil para melhorar as capacidades de gestão de riscos",
			tit6: "Suporte online de alto nível",
			p6: "Fornecendo atendimento ao cliente on-line de resposta rápida, forneceremos serviços de maior qualidade através dos esforços incessantes de uma equipe profissional",
		},
		row5: {
			tit: "Questões relacionadas",
		},
		commodities: {
			title: "Comércio de mercadorias",
			p: "Metais preciosos, energia e outras commodities são considerados capazes de resistir à inflação e têm oportunidades de valorização do investimento, tornando-os um componente importante do investimento diversificado. Entre eles, ouro, prata, petróleo e outras Variedades de comércio de commodities têm um enorme volume de negociação, com preços abertos e transparentes e alta liquidez, que têm sido amplamente acolhidos pelos investidores. Os preços das commodities flutuam significativamente devido a fatores como oferta e demanda, situações econômicas e políticas e moeda, proporcionando oportunidades de retorno de risco.",
		},
		forex: {
			title: "Operações cambiais",
			p: "Negociação cambial refere-se à conversão de uma moeda em outra, e o mercado cambial é o maior mercado financeiro de volume de negociação do mundo. Existem inúmeros participantes no mercado cambial, que fazem pagamentos, protegem contra flutuações cambiais ou se envolvem em transações para obter retornos. A negociação cambial é realizada através da internet entre bancos, instituições e comerciantes individuais em todo o mundo, com flutuações de preços ocorrendo 24 horas por dia, 5 dias por semana, proporcionando oportunidades de retorno de risco.",
		},
		indices: {
			title: "Indices",
			p: "O índice de ações é um valor estatístico que representa um Tipo específico de ações características em uma bolsa específica, que reflete o nível global de preços e as mudanças de um determinado Tipo de ações constituintes no mercado. Os índices de ações de negociação refletem oportunidades de investimento no mercado geral ou na indústria, o que pode reduzir o risco de investir em ações individuais. Os índices mais populares nos mercados dos EUA, Europa, Ásia e Austrália, como Austrália 200, US tech 100, Hong Kong 50, Alemanha 30, etc., oferecem oportunidades diferentes para os mercados de ações globais.",
		},
		shares: {
			title: "Negociação de acções",
			p: "As acções são um Tipo de títulos emitidos numa bolsa de valores. A fim de levantar fundos, as empresas alocarão a propriedade da empresa através de valores mobiliários através de uma bolsa listada. Só em 2019, o volume total de negociação de ações mundiais ultrapassou US $ 60 trilhões, com uma grande escala e forte liquidez, portanto, mesmo depois de muitos anos, continua a ser um dos instrumentos financeiros mais populares aos olhos dos investidores globais. Na plataforma FPMarkets, os traders podem usar alavancagem para negociar mudanças nos preços das ações. Os preços das ações são influenciados por vários fatores, como fatores macroeconômicos, perspectivas da indústria e operações da empresa, com alta volatilidade e oportunidades de investimento e negociação em todos os momentos.",
		},
		coins: {
			title: "Criptomoeda",
			p: "As criptomoedas são uma moeda digital criada por meio de código. Elas operam de forma autônoma, fora dos sistemas bancários e governamentais tradicionais. Como o nome sugere, as criptomoedas usam criptografia para proteger as transações e permitem a criação de outras unidades, sendo o Bitcoin a criptomoeda original e, de longe, a mais famosa, criada por Satoshi Nakamoto e lançada em janeiro de 2009. Até hoje, não se sabe se o nome Satoshi Nakamoto se refere a uma pessoa ou a um grupo de pessoas. O Bitcoin é considerado a primeira criptomoeda descentralizada. Como todas as criptomoedas, ela é controlada por um banco de dados de transações blockchain que funciona como um livro-razão público distribuído. Até o momento, há mais de 1.000 criptomoedas disponíveis para negociação on-line."
		}
	},
	trade: {
		data_sources: "Fontes de dados",
		tip: "As informações acima são apenas para referência. A FPMarkets não garante a precisão, tempo real e integridade do conteúdo da informação, por isso não deve confiar excessivamente nas informações fornecidas. Essas informações não incluem nossos registros de preços de transação, ou quaisquer ofertas ou solicitações para transações de instrumentos financeiros. A FPMarkets não é uma empresa que presta consultoria financeira, e apenas presta serviços na natureza da execução de instruções. Sugerimos que os leitores busquem conselhos de investimento relevantes por conta própria. Por favor, consulte nosso aviso completo.",
		tip2: "Atualizado todas as sextas-feiras às 15:00 (GMT)",
		tip3: "A perspectiva de negociação é uma ferramenta para prever o sentimento de negociação do mercado, refletindo as visões de curto e médio prazo dos especialistas do setor e previsões de tendências em vários Produtos populares.",
		analysis: {
			tit: "Estratégia de negociação",
			all: "Inteiro",
			day: "Dentro do dia",
			tit1: "Estratégia de negociação",
			tit2: "Revisão técnica",
		},
		calendar: {
			tit: "Calendário Económico",
			prev_week: "Semana passada",
			next_week: "Na próxima semana",
			place_date: "Data da Pesquisa",
			select: "Opção",
			select1: "País",
			select2: "Importância",
			start_time: "Hora de início",
			end_time: "Hora do fim",
			search: "Pesquisa",
		},
		contract: {
			tit: "Detalhes do contrato",
			type1: "Inteiro",
			type2: "Moeda estrangeira",
			type3: "Commodity",
			type4: "índice",
			type5: "Participações dos EUA",
			type6: "Participações Australianas",
			tit1: "Principais divisas estrangeiras",
			tit2: "Moeda estrangeira secundária",
			tit3: "Commodity",
			tit4: "Índice principal",
			tit5: "Índice secundário",
			tit6: "Participações dos EUA",
			tit7: "Participações Australianas",
			tit8: "Regras gerais",
			p1: "Proporção plana forte",
			p2: "Período de validade da encomenda",
			p3: "Ilimitado",
			p4: "Tempo de liquidação da taxa overnight",
			p5: "Liquidação diária; Verão: 05:00, Inverno: 06:00",
			p6: "Alavancagem, taxas overnight e outras informações",
			p7: "Consulte a plataforma de negociação",
			p8: "O conteúdo acima se aplica a todas as Variedades comerciais",
			tit9: "Calendário da Semana de Negociação",
			p9: "Fora do Mercado",
			p10: "Os horários de negociação acima não levam em conta o impacto de feriados ou ajustes especiais do mercado.。",
			p11: "Tempo de Transacção específico sugerido",
			p12: "Plataforma de Login",
			p13: "，Controlo da introdução de Variedades relevantes。",
		},
		forecast: {
			tit: "Perspectiva comercial",
			p1: "Valor médio",
			p2: "Bullish",
			p3: "Urso",
			p4: "Placa horizontal",
			p5: "Inclinação",
		},
		news: {
			tit: "Notícias em tempo real",
		},
		platforms: {
			tit: "Plataforma de negociação FPMarkets",
			p: "Interface simples e intuitiva, excelente desempenho comercial, para atender às necessidades de mais investidores. Vamos começar a negociar na FPMarkets hoje.",
			tit1: "Uma plataforma de negociação FPMarkets conveniente e fácil de usar",
			tit2: "Negociar com FPMarkets App a qualquer hora, em qualquer lugar",
			li1: "Negociando em centenas de mercados populares, supervisão autoritária garante segurança de fundos",
			li2: "Monitoramento em tempo real das tendências do mercado, visualização da análise de transações e navegação pelas últimas notícias financeiras",
			li3: "Visualize diferentes Tipos de gráficos de transações a qualquer momento, com função de desenho de aplicativos acessível",
			li4: "Experimente a premiada plataforma de negociação da FPMarkets no seu dispositivo Android ou iOS",
			code_download: "Transferência do Código de Digitalização",
			tit3: "Não há necessidade de baixar, negociar online através de um navegador",
			li5: "Não é necessário download, transações online diretas no navegador do PC",
			li6: "Premiado várias vezes: Melhor corretor de câmbio na Ásia em 2022, Melhor plataforma de negociação na Austrália em 2022 e Corretor de tecnologia financeira de câmbio de mais rápido crescimento no mundo em 2022",
			li7: "Fornecer indicadores técnicos poderosos tais como MACD, KDJ, RSI, TRIX, DMA, CCI, etc",
			li8: "Equipado com ferramentas de gerenciamento de risco, como stop loss e proteção contra saldo negativo para mitigar os riscos potenciais de suas transações",
			tit4: "A solução perfeita para o seu desktop trading",
			li9: "Visualize facilmente as tendências do mercado na lista selecionada",
			li10: "Ativar a função de lembrete para receber lembretes de mudança de preço e outras notificações de informações",
			li11: "Vários layouts de gráficos permitem que você acompanhe as tendências do mercado a partir de diferentes dimensões temporais",
			li12: "Especificações de contrato, sentimento de transação e análise de transação estão todos na mesma interface, sem a necessidade de trocar",
			li13: "Receba as últimas notícias de negociação diretamente no FPMarkets",
			tit5: "Por que escolher a plataforma de negociação FPMarkets?",
			row5_p1: "Garantia de segurança",
			row5_p2: "0 comissão",
			row5_p3: "Suporte a várias plataformas",
			row5_p4: "Gráficos técnicos",
			row5_p5: "Acesso a qualquer lugar",
			row5_p6: "Várias ferramentas de desenho",
		},
		quotes: {
			tit: "Cotação em tempo real",
			p1: "Nós fornecemos cotações em tempo real, sentimento do mercado e notícias relacionadas sobre Produtos financeiros populares atuais.",
			hot: "Popular",
		},
		risk: {
			tit: "Gestão de riscos",
			p1: "Todas as transações envolvem riscos, e as ferramentas gratuitas de gerenciamento de risco fornecidas pela FPMarkets podem ser usadas de forma flexível para controlar efetivamente os riscos, independentemente das condições de mercado.",
			function_tit1: "Função Stop Profit/Stop Loss",
			function_tit2: "Função de controlo de stop loss",
			function_p1: "Bloqueio dos lucros",
			function_p2: "Perdas limitadas",
			function_p3: "Maximizar os lucros bloqueados",
			function_p4: "Não há necessidade de monitorar a posição automaticamente",
			function_p5: "Ajustamento do preço de encerramento em função das perdas",
			row2_tit1: "Função Stop Profit/Stop Loss",
			row2_p1: "Ao estabelecer novas ordens ou modificar ordens existentes, podemos escolher definir 'stop profit' e 'stop loss'. Após a configuração, as ordens geralmente serão liquidadas com base no preço alvo definido, ajudando você a bloquear lucros ao atingir a meta ou reduzir perdas quando a tendência do mercado é desfavorável. Por favor, note que qualquer ordem pode experimentar uma lacuna devido às flutuações do mercado, e o sistema não será capaz de executar a ordem no preço padrão, mas irá fechar a posição para você no preço mais favorável perto do preço alvo.",
			row2_tit2: "Exemplo",
			row2_p2: "O preço atual de EUR/USD é 1.13816/1.13837 (vender/comprar). Você estabeleceu uma ordem de compra de uma mão (uma mão = 100000 euros) em 1.13837, e definiu uma ordem de stop loss em 1.13806.",
			row2_p3: "Em geral, quando o preço cai para 1,13806, sua ordem de stop loss será acionada e a posição será fechada ao preço de 1,13806, resultando em uma perda total de US $ 31.",
			row2_p4: "No entanto, quando o ambiente do mercado sofre uma mudança repentina e o preço cai diretamente de 1,13837 para 1,13795, ignorando sua meta de stop loss, o mercado experimentará uma lacuna e o sistema não será capaz de fechar a posição em 1,13806. No entanto, o próximo preço mais favorável, 1,13795, será usado para fechar a posição para você, resultando em uma perda final de US $ 42.",
			row3_tit1: "Função de controlo de stop loss",
			row3_tit2: "Exemplo",
			row3_p1: "A função stop loss de rastreamento (também conhecida como stop loss móvel) é poderosa, e o preço stop loss pode mudar automaticamente com lucros, permitindo que você maximize lucros bloqueados ou reduza perdas sem monitorar constantemente sua posição. Quando o preço se move em uma direção favorável para você, sua ordem stop loss será atualizada automaticamente com base no preço mais recente. Pelo contrário, quando o preço mudar em uma direção desfavorável para você, a ordem stop loss será iniciada e a posição será fechada dentro da distância dos pontos de perda que você definir. Por favor, note que qualquer ordem pode experimentar uma lacuna devido às flutuações do mercado, e o sistema não será capaz de executar a ordem em seu preço padrão, mas irá fechar a posição para você no preço mais favorável perto do preço alvo.",
			row3_p2: "O preço atual para EUR/USD é 1.13816/1.13837 (vender/comprar). Você estabeleceu uma ordem de compra de uma mão em 1.13837 e definiu um stop loss de rastreamento de 100 pontos (0.00100).",
			row3_p3: "Quando o preço do Produto é de 1,13816, seu preço de stop loss é de 1,13716. Se o preço de venda do Produto aumentar para 1,13845, o preço de stop loss será atualizado de acordo com a distância que você definir, e o preço de stop loss atualizado é 1,13745.",
			row3_p4: "Pelo contrário, quando o preço do Produto cair de 1,13845 para 1,13745, o rastreamento stop loss será acionado e a posição de fechamento será executada em 1,13745.",
			tip: "Ponto: Normalmente, a unidade mínima de mudança no preço dos instrumentos financeiros é chamada de ponto. Na plataforma FPMarkets, refere-se ao último dígito ou decimal do preço da ferramenta.",
		},
		sentiment: {
			tit: "Índice Emocional",
			type1: "Inteiro",
			type2: "Moeda estrangeira",
			type3: "Commodity",
			type4: "índice",
			long: "Longo",
			short: "Posição urso",
		},
	},

	layout: {
		aside: {
			nav1: "Negociar",
			nav2: "Mercado",
			nav3: "Notícias",
			nav4: "Tutoriais",
			nav5: "Conta",
			newsDialog: {
				title: "Centro de Mensagens",
				type1: "Notificações do sistema",
				type2: "Anúncio",
				allRead: "Marcar tudo como lido",
			},
			settingDialog: {
				title: "Configurar",
				nav1: "Rotina",
				nav2: "Preferências de visualização",
				nav3: "Informação do",
				logout: "Sair do login",
				setting1: "Língua",
				setting2: "Transacção",
				setting2_tip: "Adição automática de Variedades de armazém aberto para auto-seleção",
				setting3: "Feedback",
				setting3_tip: "Recomendações funcionais",
				setting4: "Autenticação de nomes reais",
				setting4_tip: "Autenticação de nomes reais",
				setting5: "Modo Tema",
				setting5_label1: "Escuro",
				setting5_label2: "Cor brilhante",
				setting6: "Cor dos altos e baixos",
				setting6_label1: "Ascensão verde e queda vermelha",
				setting6_label2: "Ascensão vermelha e queda verde",
				setting7: "Configuração do Gráfico",
				setting7_label1: "Edição Normal",
				setting7_label2: "Edição de Visualização de Negociação",
				setting8: "Declaração e acordo",
				setting8_label1: "Política de Privacidade",
				setting8_label2: "Declaração de Divulgação do Produto",
				setting8_label3: "Declaração de Divulgação de Riscos",
				setting8_label4: "Acordo com o Cliente",
				feedback_title: "Recomendações funcionais",
			}
		},
		footer: {
			hot: "Variedades Populares",
			sort: "Variedade",
			code: "Código",
			buy_price: "Compra",
			sell_price: "Preço de venda",
			chg: "Intervalo de flutuação",
		},
		header: {
			place_search: "Procurar Variedades de transacções",
			balance: "Saldo disponível",
			balance_tip: "Saldo disponível: O valor na conta que pode ser usado para abrir uma nova posição",
			profit_loss: "P/L",
			asset_view: "Visão geral dos ativos",
			netValue: "Património líquido",
			netValue_tip: "Valor líquido: O valor da conta corrente, incluindo os P/L de todas as posições detidas",
			marginLevel: "Nível da margem",
			margin: "Margem",
			margin_tip: "Margem: O montante necessário para abrir e manter todas as posições",
			maintainsMargin: "Manter a margem",
			maintainsMargin_tip: "Manter Margem: A quantidade mínima de margem que você precisa manter em sua conta ao manter todas as posições.",

		},
	},
	table: {
		label_name: "Nome",
		label_buyPrice: "Compra",
		label_sellPrice: "Venda",
		label_variableValue: "Mudar",
		trade: "Transacção",
		label_code: "Código do Produto",
		label_title: "Nome",
		label_start: "Importância",
		label_country: "País",
		label_event: "Evento",
		label_previous: "Valor anterior",
		label_predictive: "Estimativa",
		label_announced: "Valor anunciado",
		label_stop_loss_min: "Distância mínima da ordem de stop loss",
		label_difference: "Diferença dinâmica de preços",
		label_contract_size: "Dimensão do contrato",
		label_max_hand: "Número máximo de transacções por Transacção",
		label_min_hand: "Número mínimo de operações por operação",
		label_trade_week: "Calendário da Semana de Negociação",
		label_week: "1 semana",
		label_month: "1mês",
		label_quarter: "1trimestre",
		label_dailyTrend: "Tendência diária",
		label_tradeId: "Transacção ID",
		label_breed: "Variedade",
		label_tradeType: "Tipo de Transacção",
		label_currency: "Moeda",
		label_amount: "Dinheiro",
		label_balance: "Equilíbrio",
		label_type: "Tipo",
		label_time: "Tempo",
		label_orderNumber: "Número de",
		label_pointer_number: "Contagem de",
		label_price_money: "Preço de",
		label_stop_win_price: "Parar o",
		label_stop_lose_price: "Parar a perda",
		label_openTime: "Tempo",
		label_profit_loss: "Lucros e",
		label_sell_buy: "Preço de venda/compra",
		label_chg: "Intervalo de flutuação",
		label_sell_price: "Preço de venda",
		label_buy_price: "Compra",
		label_condition: "Condição",
	},
	form: {
		label_feedback: "Perguntas e sugestões",
		place_feedback: "Insira sua pergunta ou sugestão",
		label_img: "Imagem",
		label_img_tip: "Opcional, forneça capturas de tela do problema",
		feedback_tip: "Se você tiver perguntas urgentes, entre em contato",
		online: "Serviço Online",
		label_name: "Nome",
		place_name: "Por favor indique o seu nome",
		label_email: "E-mail",
		place_email: "Endereço de e- mail",
		message_email: "Introduza o seu endereço de e-mail",
		label_nationality: "Nacionalidade",
		place_nationality: "Indique a sua nacionalidade",
		place_questionType: "Tipo de Problema",
		message_questionType: "Seleccione por favor um Tipo",
		questionType0: "Plataforma de negociação",
		questionType1: "Produtos e taxas",
		questionType2: "Subscreva a análise diária de mercado",
		questionType3: "Atendimento ao cliente",
		questionType4: "Outros",
		place_question: "A sua pergunta",
		message_question: "Por favor insira uma pergunta",
		submit_question: "Submeter Questões",
		label_phone: "Número de telemóvel",
		place_phone: "Introduza o seu número de telefone",
		message_phone: "Introduza o seu número de telefone",
		label_password: "Senha",
		place_password: "Introduza a senha",
		message_password: "Introduza a senha",
		label_confirmPassword: "Confirmar a Senha",
		place_confirmPassword: "Introduza a senha novamente",
		message_confirmPassword: "Introduza a senha novamente",
		label_captcha: "Código de verificação",
		place_captcha: "Introduza o código de verificação",
		message_captcha: "Introduza o código de verificação",
		get_captcha: "Obter código de verificação",
		label_inviteId: "Código do convite",
		place_inviteId: "Código do convite (facultativo)",
		to: "a",
		start_time: "Aora de início",
		end_time: "Hora do fim",
		label_cardNumber: "Número de identificação",
		place_cardNumber: "Introduza o seu número de identificação",
		message_cardNumber: "Introduza o seu número de identificação",
		label_cardMain: "Frente do ID",
		message_cardMain: "Por favor, indique a frente do documento de identificação",
		label_cardBack: "No verso do documento",
		message_cardBack: "Introduza por favor o lado inverso do ID",
		confirm_modify: "Confirmar a modificação",
		label_realName: "Nome completo",
		place_realName: "Indique o seu primeiro nome",
		message_realName: "Indique o seu nome completo",
		label_firstName: "Apelido",
		place_firstName: "Por favor, indique o seu sobrenome",
		message_firstName: "Indique por favor o apelido",
		label_lastName: "Nome",
		place_lastName: "Por favor indique o seu nome do meio",
		message_lastName: "Indique por favor um nome",
		label_birthday: "Data de nascimento",
		place_birthday: "Indique a data de nascimento",
		message_birthday: "Indique a data de nascimento",
		place_nowPsd: "Indique por favor a senha actual",
		message_nowPsd: "Indique por favor a senha actual",
		place_newPsd: "Introduza uma nova senha",
		message_newPsd: "Introduza uma nova senha",
		place_crmPsd: "Por favor confirme a nova senha",
		message_crmPsd: "Por favor confirme a nova senha",
		label_breed: "Variedade",
		place_breed: "Indique a Variedade",
		label_phase: "Quando",
		label_buyPrice: "Compra",
		label_sellPrice: "Preço de venda",
		label_do: "Por",
		label_height: "Acima",
		label_low: "Sob",
		label_equal: "Ou Igual a",
		labelWalletName: "Nome da carteira",
		messageWalletName: "Indique por favor o nome da carteira",
		placeWalletName: "Indique por favor o nome da carteira",
		labelWalletAddress: "Endereço da carteira",
		messageWalletAddress: "Indique por favor o endereço da carteira",
		placeWalletAddress: "Indique por favor o endereço da carteira",
		labelAmount: "Dinheiro",
		messageAmount: "Indique o montante",
		placeAmount: "Indique o montante",
		placeTimeLimit: "Indique por favor um limite de tempo",
		messageTimeLimit: "Indique por favor um limite de tempo",
	},
	pay: {
		title: "Depósito e levantamento convenientes",
		p1: "Vários canais de pagamento comumente usados, apoiando depósito rápido e retirada",
		p2: "Alguns métodos de pagamento podem não estar disponíveis no seu país/região",
		p3: "Bem-vindo à experiência dos serviços FPMarkets",
		btn: "Transacção",
	},
	header: {
		tip1: "Negociação alavancada é um Produto financeiro complexo com alto risco de perdas rápidas.",
		btn1: "Plataforma de negociação",
		btn2: "Sair do login",
		btn3: "Login",
		BigUint64Array: "Trade Agora",
	},
	footer: {
		help: "Contate-nos",
		tip1: "Siga a nossa comunidade",
		tip2: "Por favor, note que a FPMarkets não estabeleceu um grupo oficial do Telegram, e quaisquer grupos do Telegram formados sob o nome da FPMarkets são suspeitos de fraude.",
		tip3: "Aviso de risco: A negociação pode fazer com que você perca todos os seus fundos. A negociação de derivados OTC não é adequada para todos. Leia atentamente os nossos documentos legais antes de utilizar os nossos serviços e certifique-se de que compreende completamente os riscos envolvidos antes de negociar. Você não possui ou detém nenhum ativo subjacente relacionado.",
		tip4: "A FPMarkets não fornece nenhum conselho, recomendações ou opiniões sobre compra, detenção ou venda. Todos os Produtos que fornecemos são derivados de balcão baseados em ativos globais. Todos os serviços fornecidos pela FPMarkets baseiam-se exclusivamente na execução de instruções de transação.",
		tip5: "FPMarkets é uma marca de negócios comumente usada por várias empresas e opera através das seguintes empresas:",
		tip6: "A FPMarkets International Ltd é a emissora de Produtos financeiros descritos ou disponíveis para uso neste site. A FPMarkets International Ltd é autorizada e regulamentada pela Comissão de Serviços Financeiros das Maurícias (FSC) com número de licença GB20025791 e endereço registrado em 6 St Denis Street, 1st Floor River Court, Port Louis 11328, Maurícia",
		tip7: "A FPMarkets Global Pty Ltd tem um número de registro ABN 90 149 011 361 e um número de licença de serviços financeiros australianos (AFSL) 398528.",
		tip8: "A FPMarkets Holding Ltd é autorizada e regulada pela Autoridade Monetária das Ilhas Caimão (CIMA) com um número de licença SIB 1612446.",
		tip9: "As informações contidas neste site não se destinam a residentes dos Estados Unidos, Canadá, Japão ou Nova Zelândia; As informações contidas neste site não são adequadas para ninguém em qualquer país ou jurisdição que viole as leis ou regulamentos locais devido à sua publicação ou uso. Observe que o inglês é o idioma principal usado em nossos serviços e também o idioma juridicamente vinculativo em todos os nossos documentos contratuais. Em caso de ambiguidade entre a tradução do texto e o inglês, prevalecerá a versão em inglês.",
		tip10: "Criptografia de comunicação segura SSL. ©  FPMarkets Todos os direitos reservados.",
		link1: "Política de Privacidade",
		link2: "Declaração de Divulgação do Produto",
		link3: "Processo de reclamação",
		link4: "Declaração de Divulgação de Riscos",
		link5: "Acordo com o Cliente",
		toTop: "Topping",
	},
	nav: {
		tit1: "Produto",
		tit2: "Transacção",
		tit3: "Aprender",
		tit4: "Sobre nós",
		nav1: "moeda estrangeira",
		nav2: "índice",
		nav3: "commodity",
		nav4: "acções",
		nav5: "plataforma de negociação",
		nav6: "Estratégia de negociação",
		nav7: "Perspectiva comercial",
		nav8: "Calendário Económico",
		nav9: "Notícias em tempo real",
		nav10: "Cotação em tempo real",
		nav11: "Índice Emocional",
		nav12: "gestão de riscos",
		nav13: "Detalhes do contrato",
		nav14: "Introdução ao investimento",
		nav15: "Insight do investimento",
		nav16: "Academy",
		nav17: "Sobre o FPMarkets",
		nav18: "Prémios e Honras",
		nav19: "Centro de Mídia",
		nav20: "Segurança financeira",
		nav21: "Taxas e taxas",
		nav22: "Affiliates",
		nav23: "Contacte-nos",
		nav24: "Perguntas Frequentes",
		nav25: "Centro de Ajuda",
	},

	city: {
		albania: "Albânia",
		algeria: "Argélia",
		argentina: "Argentina",
		armenia: "Arménia",
		australia: "Austrália",
		pakistan: "Paquistão",
		austria: "Áustria",
		bahrain: "Bahrein",
		belgium: "Bélgica",
		bosnia_and_Herzegovina: "Bósnia e Herzegovina",
		brazil: "Brasil",
		brunei: "Brunei",
		bulgaria: "Bulgária",
		cambodia: "Camboja",
		canada: "Canadá",
		cameroon: "Camarões",
		chile: "Chile",
		colombia: "Colômbia",
		costa_Rica: "Costa Rica",
		croatia: "Croácia",
		cyprus: "Chipre",
		czech_Republic: "República Checa",
		denmark: "Dinamarca",
		dominican_Republic: "República Dominicana",
		egypt: "Egipto",
		estonia: "Estónia",
		ethiopia: "Etiópia",
		finland: "Finlândia",
		france: "França",
		georgia: "Geórgia",
		germany: "Alemanha",
		ghana: "Gana",
		greece: "Grécia",
		guyana: "Guiana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, China",
		hungary: "Hungria",
		iceland: "Islândia",
		ireland: "Irlanda",
		italy: "Itália",
		india: "Índia",
		indonesia: "Indonésia",
		israel: "Israel",
		iran: "Irão",
		iraq: "Iraque",
		japan: "Japão",
		kazakstan: "Cazaquistão",
		kenya: "Quénia",
		korea: "Coreia",
		kuwait: "Kuwait",
		kyrgyzstan: "Quirguizistão",
		laos: "Laos",
		latvia: "Letónia",
		lithuania: "Lituânia",
		luxembourg: "Luxemburgo",
		macao_China: "Macau, China",
		macedonia: "Macedónia",
		malaysia: "Malásia",
		malta: "Malta",
		mexico: "México",
		moldova: "Moldávia",
		monaco: "Mônaco",
		mongolia: "Mongólia",
		montenegro: "Montenegro",
		morocco: "Marrocos",
		myanmar: "MianmarName",
		netherlands: "Países Baixos",
		new_Zealand: "Nova Zelândia",
		nepal: "Nepal",
		nigeria: "Nigéria",
		norway: "Noruega",
		oman: "Omã",
		palestine: "Palestina",
		panama: "Panamá",
		paraguay: "Paraguai",
		peru: "Peru",
		philippines: "Filipinas",
		poland: "poland",
		portugal: "Portugal",
		puerto_Rico: "Porto Rico",
		qatar: "Qatar",
		romania: "Roménia",
		russia: "Rússia",
		republic_of_Trinidad_and_Tobago: "Trinidad e Tobago",
		rwanda: "Ruanda",
		saudi_Arabia: "Arábia Saudita",
		serbia: "Sérvia",
		singapore: "Singapura",
		slovakia: "Eslováquia",
		slovenia: "Eslovénia",
		south_Africa: "África do Sul",
		spain: "Espanha",
		sri_Lanka: "Sri Lanka",
		sweden: "Suécia",
		switzerland: "Suíça",
		taiwan_China: "Taiwan, China",
		tajikistan: "Tajiquistão",
		tanzania: "Tanzânia",
		thailand: "Tailândia",
		turkey: "Türkiye",
		turkmenistan: "Turquemenistão",
		ukraine: "Ucrânia",
		united_Arab_Emirates: "EAU",
		united_Kingdom: "Grã-Bretanha",
		united_States: "Estados Unidos",
		uzbekistan: "Usbequistão",
		venezuela: "Venezuela",
		vietnam: "Vietname",
		afghanistan: "Afeganistão",
		angola: "Angola",
		azerbaijan: "Azerbaijão",
		bangladesh: "Bangladesh",
		belarus: "Bielorrússia",
		belize: "Belizefrance. kgm",
		benin: "Benin",
		bhutan: "Butão",
		bolivia: "bolívia",
		botswana: "Botsuana",
		british_Virgin_Islands: "Ilhas Virgens Britânicas",
		burkina_Faso: "burkina faso",
		burundi: "Burundi",
		cape_Verde: "Cabo Verde",
		cayman_Islands: "Ilhas Caimão",
		central_African_Republic: "República Centro-Africana",
		chad: "ChadeName",
		comoros: "Comores",
		the_Republic_of_the_Congo: "Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Congo (Kinshasa)",
		djibouti: "JibutiName",
		ecuador: "Equador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Guiné Equatorial",
		eritrea: "Eritreia",
		fiji: "Fiji",
		gabon: "Gabão",
		gambia: "Gâmbia",
		greenland: "Gronelândia",
		guatemala: "Guatemala",
		guinea: "Guiné",
		haiti: "Haiti",
		isle_of_Man: "Ilha de Man",
		cote_d_Ivoire: "Costa do Marfim",
		jamaica: "Jamaica",
		jordan: "Jordânia",
		lebanon: "Líbano",
		lesotho: "Lesoto",
		liberia: "Libéria",
		libya: "Líbia",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldivas",
		mali: "Mali",
		mauritania: "Mauritânia",
		mauritius: "Maurícia",
		mozambique: "Moçambique",
		namibia: "Namíbia",
		nicaragua: "Nicarágua",
		republic_of_Niger: "Níger",
		north_Korea: "Coreia do Norte",
		reunion: "Reunião",
		san_Marino: "San Marino",
		senegal: "SenegalName",
		sierra_Leone: "sierra leone",
		somalia: "Somália",
		sudan: "Sudão",
		suriname: "Suriname",
		eswatini: "Eswatini",
		syria: "Síria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunísia",
		united_States_Virgin_Islands: "Ilhas Virgens, Estados Unidos",
		uganda: "Uganda",
		uruguay: "Uruguai",
		vatican: "Vaticano",
		yemen: "Iémen",
		yugoslavia: "Jugoslávia",
		zambia: "Zâmbia",
		zimbabwe: "zimbabwe",
		china: "China",
	}
};
