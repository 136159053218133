import Vue from 'vue';
import VueI18n from 'vue-i18n';
import elementZHTW from 'element-ui/lib/locale/lang/zh-TW'
import elementEN from 'element-ui/lib/locale/lang/en'
import elementKO from 'element-ui/lib/locale/lang/ko'
import elementPTBR from 'element-ui/lib/locale/lang/pt-br'
import elementES from 'element-ui/lib/locale/lang/es'
import elementTH from 'element-ui/lib/locale/lang/th'
import elementVI from 'element-ui/lib/locale/lang/vi'
import elementID from 'element-ui/lib/locale/lang/id'
import elementJp from 'element-ui/lib/locale/lang/ja'
Vue.use(VueI18n);

//导入语言包
import Zh from '../lang/zh.js'; // 中文语言包
import Tc from '../lang/zh-tw.js'; // 中文繁体语言包
import En from '../lang/en.js'; // 英文语言包
import In from '../lang/in.js'; // 印地语言包
import Kr from '../lang/ko.js'; // 韩语语言包
import Pt from '../lang/pt-br.js'; // 葡萄牙语言包
import Es from '../lang/es.js'; // 西班牙语言包
import Th from '../lang/th.js'; // 泰语语言
import Vn from '../lang/vi.js'; // 越南
import Id from '../lang/id.js'; // 印尼
import My from '../lang/my.js'; // 马来
import Jp from '../lang/jp.js'; // 马来

const messages = {
    tc: {
        ...elementZHTW,
        ...Tc
    },
    en: {
        ...elementEN,
        ...En
    },
    in: {
        ...elementEN,
        ...In
    },
    ko: {
        ...elementKO,
        ...Kr
    },
    pt: {
        ...elementPTBR,
        ...Pt
    },
    es: {
        ...elementES,
        ...Es
    },
    th: {
        ...elementTH,
        ...Th
    },
    vn: {
        ...elementVI,
        ...Vn
    },
    id: {
        ...elementID,
        ...Id
    },
    my: {
        ...elementEN,
        ...My
    },
    jp: {
        ...elementJp,
        ...Jp
    },

}
window.localStorage.setItem("lang", 'en')
export default new VueI18n({
    locale: 'en', // set locale 默认显示英文
    fallbackLocale: 'en', //如果语言包没有，则默认从中文中抽取
    messages: messages // set locale messages
});
