import request from "@/utils/request";

export var uploadDomain = "https://api.fpmarketsapi.com/api/v1/storage/upload";

export var serviceUrl = "https://chat.ichatlink.net/widget/standalone.html?eid=5c7e312e27528ad0fdd5fcb81b6bab64&language=ja"

// const baseDomain = "ws://127.0.0.1:30020/ws"
const baseDomain = "wss://api.fpmarketsapi.com/ws"

export var baseUsdtId = 100

export var dataDomain = baseDomain + "/data";
export var depthDomain = baseDomain + "/depth";
export var kDataDomain = baseDomain + "/kdata";

export function getSystemParam(query) {
    return request({
        url: "/api/v1/system/system",
        method: "get",
        params: query,
    });
}

export function getItemList(query) {
    return request({
        url: "/api/v1/system/category",
        method: "get",
        params: query,
    });
}

export function getItemDetail(id) {
    return request({
        url: "/api/v1/system/category/" + id,
        method: "get",
    });
}

export function getLinkList(query) {
    return request({
        url: "/api/v1/system/link",
        method: "get",
        params: query,
    });
}

export function getArticleList(query) {
    return request({
        url: "/api/v1/system/article",
        method: "get",
        params: query,
    });
}

export function getArticleDetail(id) {
    return request({
        url: "/api/v1/system/article/" + id,
        method: "get",
    });
}

export function getArticleTypeList(query) {
    return request({
        url: "/api/v1/system/article-type",
        method: "get",
        params: query,
    });
}

export function getArticleTypeDetail(id) {
    return request({
        url: "/api/v1/system/article-type/" + id,
        method: "get",
    });
}
