export default {
	common: {
		award1: "2023年アジア最適価格差契約ブローカー",
		award2: "2022グローバルベスト・エージェンシー",
		award3: "2022年に最も信頼できるブローカー",
		award4: "2021年間で最も信頼できるブランド",
		award5: "ベストファイナンシャルテクノロジーブローカー",
		award6: "最も取引透明性の高いブローカー",
		country: "解析",
		health: "健康",
		analysis: "国",
		information: "情報＃ジョウホウ＃",
		help: "ヘルプ",
		confirm: "を選択して、",
		cancel: "キャンセル",
		tip: "ヒント",
		place_select: "選択してください",
		placeEnter: "入力してください",
		countryCode: "国コード",
		submit: "送信",
		more: "詳細",
		deposit: "入金",
		real_trade: "実際の取引",
		paper_trade: "シミュレーション取引",
		build_paperTrade: "シミュレーション勘定科目の作成",
		start_trade: "今すぐ取引を開始",
		app_name: "FPMarkets",
		success: "成功！",
		dataSources_name: "Trading Central",
		reset: "リセット",
		iknow: "わかりました",
		noData: "データが一時的にありません",
		modify: "変更",
		pingcang: "平倉",
		all: "inteiro",
		tip_cardMain: "身分証明書の正面の写真を撮影またはアップロードする",
		tip_cardBack: "身分証明書の裏の写真を撮影またはアップロードする",
		tip_cardMain1: "運転免許証の正面の写真を撮影またはアップロードする",
		tip_cardBack1: "運転免許証の背後の写真を撮影またはアップロードする",
		tip_cardMain2: "パスポート写真の撮影またはアップロード",
		tip_cardBack2: "パスポート写真の撮影またはアップロード",
	},
	currency: {
		btcusdt: "ビットコイン",
		ethusdt: "エーテル坊」、",
		ltcusdt: "ライト貨幣",
		xrpusdt: "瑞波貨幣",
		adausdt: "アイダコイン",
		eosusdt: "柚子コイン",
		dotusdt: "ボカコイン",
		trxusdt: "波場貨幣",
		xmrusdt: "モンロードル",
		dogeusdt: "ドッグ？コイン",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "豪ドル",
		fx_sgbpusd: "ポンド・ドル",
		fx_seurusd: "ユーロ・ドル",
		fx_snzdusd: "ニュージーランドドル（ニュードル）",
		fx_susdcad: "ドルカナダドル",
		fx_susdchf: "ドル瑞郎",
		fx_susdjpy: "ドル円",
		fx_saudcad: "豪ドルカナダドル",
		fx_saudchf: "豪ドル瑞郎",
		fx_saudjpy: "豪ドル円",
		fx_saudnzd: "豪ドルニュージーランドドル（ニュードル）",
		fx_scadchf: "加元瑞郎",
		fx_scadjpy: "カナダ円",
		fx_schfjpy: "瑞郎円",
		fx_seuraud: "ユーロ豪ドル",
		fx_seurcad: "ユーロカナダドル",
		fx_seurgbp: "ユーロポンド",
		fx_seurjpy: "ユーロ円",
		fx_seurnzd: "ユーロニュージーランドドル（ニュードル）",
		fx_sgbpaud: "ポンド豪ドル",
		fx_sgbpchf: "スターリング・レイラン",
		fx_sgbpjpy: "ポンド円",
		fx_sgbpnzd: "ポンドニュージーランドドル（ニュードル）",
		fx_scadnzd: "カナダドル",
		hf_CL: "WTIニューヨーク原油",
		hf_OIL: "ブレント原油",
		hf_GC: "ニューヨーク金",
		hf_SI: "ニューヨーク銀",
		hf_HG: "びどう",
		hf_NG: "アメリカガス",
		hf_CAD: "ロンドン銅",
		hf_AHD: "アルミニウムロンドン",
		HX_AAPL: "リンゴ",
		HX_BABA: "アリババ",
		HX_GOOG: "グーグル",
		HX_WMT: "ウォルマート",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "テスラ.",
		HX_N225: "日経225",
		HX_GDAXI: "ドイツDAX 30",
		HX_IBEX: "スペインIBEX 35",
		HX_SPX: "スタンダード500指数",
		HX_FTSE: "英国リッチタイム100",
		HX_KS11: "韓国KOSPI",
		HX_NZ50: "ニュージーランド50",
		HX_PSI20: "ポルトガルPSI 20",
		hf_ZSD: "ロンドン亜鉛",
		hf_NID: "ロンドンニッケル",
		hf_PBD: "ロンドン鉛",
		hf_SND: "ロンドンすず",
		hf_XAU: "ロンドン金（現物金）",
		hf_XAG: "ロンドン銀（現物銀）",
		hf_XPT: "プラチナ先物",
		hf_XPD: "パラジウム先物",
		hf_CT: "アメリカ綿",
		hf_SM: "きな粉",
		hf_BO: "クマリン油",
		HX_SENSEX: "インド・ムンバイSENSEX",
		HX_KLSE: "富時マレーシアKLCI",
		HX_KSE100: "パキスタンカラチ",
		HX_FCHI: "フランスCAC 40",
		HX_SX5E: "ヨーロッシュノーケル50",
		HX_ICEXI: "アイスランドICEX",
		HX_ASE: "ギリシャアテネASE",
		HX_OMXC20: "OMXコペンハーゲン20",
		HX_OSEBX: "ノルウェーOSEBX",
		HX_OMXSPI: "スウェーデンOMXSPI",
		HX_AMD: "超威トランジスタ",
		HX_DIS: "ディズニーランド",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "マスター",
		HX_NVDA: "英偉達",
		HX_INTC: "インテル",
		HX_GOGO: "Gogo Inc",
		HX_STX: "希捷科技",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "アトム貨幣",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
	currenctType: {
        title: "通貨を選択してください",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'ドル',
        currenctType15: '香港ドル',
        currenctType16: '台湾ドル',
        currenctType17: 'コイン枚',
        currenctType18: '新貨',
        currenctType19: '円',
        currenctType20: 'ユーロ',
        currenctType21: '豪ドル',
        currenctType22: 'インドネシア盾',
        currenctType23: 'ポンド',
        currenctType24: 'バーツ',
        currenctType25: 'かさん',
        currenctType26: 'ベトナムドン',
        currenctType27: '韓国ドル',
        currenctType28: 'マカオドル',
    },
    addnew2: {
        address: {
            title: "出金先",
            account_number: "勘定科目番号",
            add_btn: "アドレスを追加",
            label_type: "タイプの選択",
            place_type: "タイプを選択してください",
            label_number: "勘定科目番号",
            place_number: "勘定科目番号を入力してください",
            wallet_address: 'ウォレットアドレス',
            bank_name: '銀行名',
            bank_address: "銀行住所",
            name: "名前",
            payee_address: '受取人住所',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "支店名",
            branch_code: "支店コード",
            bank_code: "銀行コード",
            phone: "携帯番号",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "内容を入力してください",
        },
    },
	addnew: {
		paypassTit: "引き出しパスワードの変更",
		paypassTit1: "お引き出しパスワードを設定してください",
		paypassTit2: "コミュニケーションパスワードの初期設定はログインパスワードです",
		loan: "ローン",
		repaid: "返済済み",
        unpaid: "未返済",
		loanMore: "私はローンを借りたい",
		immediateRepayment: "即時返済",
		accountType1: "通貨勘定科目",
		accountType2: "契約勘定科目",
		accountType3: "オプションアカウント",
		from: "開始",
        to: "終了",
		get: "すぐに",
		labelBreed: "通貨＃ツウカ＃",
		placeBreed: "通貨を選択してください",
		labelTransAccount: "振替勘定科目",
		labelAmount: "振替金額",
		placeAmount: "振替金額を入力してください",
		transferTit:"口座振替",
		convertTit:"貨幣フラッシュ両替",
		balanceTit:"勘定残高",
		available: "使用可能な限度",
        pending: "ロック＃ロック＃",
        equivalent: "換算",
		coinTrade: "貨幣取引",
		secondContract: "秒契約",
		number: "數量",
		labelNumber: "數量",
		placeNumber: "償還数量を入力してください",
		time: "時間",
        loadAmount: "予定借款金額",
        repaymentCycle: "ローン返済サイクル",
        dailyRate: "日利率",
        repaymentMethod: "返済方法",
        loanTip: "クレジットローン（画像がはっきり見えるようにしてください）",
        loanTip1: "不動産証明書のアップロード",
        loanTip2: "収入証明（雇用関係）",
        loanTip3: "銀行取引明細書詳細",
        loanTip4: "身分証明書の正面写真をアップロードする",
        interest: "興味",
		repaymentMethod1: "期限切れの返済",
	},
	params: {
		product_foreign: "外国為替",
		product_shop: "商品",
		product_number: "しすう",
		product_stock: "ストック",
		product_coin: "暗号化",
		billTypes: [
            { v: 101, name: 'チャージ' },
            { v: 102, name: '追加' },
            { v: 103, name: 'とうけつ' },
            { v: 104, name: '解凍' },
			{v: 105, name: 'よびだし'},
			{v: 106, name: 'よびだし'},
            { v: 201, name: '引き出し凍結' },
            { v: 202, name: '差し引く' },
            { v: 203, name: '引き出し成功' },
            { v: 204, name: '引き出しに失敗しました' },
            { v: 205, name: '引き出し手数料' },
			{v: 206, name: '転出'},
			{v: 207, name: '転入'},
			{v: 208, name: '貨幣振替'},
			{v: 209, name: '貨幣振替'},
            { v: 301, name: '保証金控除' },
            { v: 302, name: '手数料控除' },
            { v: 303, name: '保証金返還' },
            { v: 304, name: 'に利益を与える' },
            { v: 305, name: '損失' },
			{v: 311, name: '購入オプション'},
			{v: 312, name: 'オプション収益'},
			{v: 313, name: 'オプション返品'},
			{v: 314, name: 'オプション手数料'},
			{v: 315, name: '貨幣購入凍結'},
			{v: 316, name: '貨幣購入控除'},
			{v: 317, name: '貨幣購入リターン'},
			{v: 318, name: '貨幣購入先帳'},
			{v: 319, name: '貨幣購入先帳'},
			{v: 320, name: '貨幣販売凍結'},
			{v: 321, name: '貨幣売却控除'},
			{v: 322, name: '貨幣売り戻し'},
			{v: 323, name: '貨幣が帳簿に売れる'},
			{v: 324, name: '貨幣が帳簿に売れる'},
			{v: 325, name: '貨幣手数料'},
			{v: 401, name: '鉱機参入'},
			{v: 402, name: '鉱機が引き返す'},
			{v: 403, name: '鉱機参入'},
			{v: 404, name: '鉱山機の退出'},
			{v: 405, name: '鉱機退出手数料'},
			{v: 411, name: '借入金収入'},
			{v: 412, name: '借入手数料'},
			{v: 413, name: '返済成功'},
        ],
	},
	message: {
		logout: "ログアウト",
		register_success: "登録に成功しました",
		place_inner_personal: "まず個人情報を入力してください",
		submit_success: "コミット成功！",
		copy_success: "コピー成功！",
		copy_error: "コピーに失敗しました！",
		modify_success: "変更に成功しました",
		no_balance: "残高不足支払保証金",
		palce_password: "パスワードを入力してください",
		trade_success: "取引成功",
		placeEnterAmount: "金額を入力してください",
		tipTit1: "この注文書を支払いますか。",
	},
	web: {
		account: {
			menu1: "アカウント情報",
			menu2: "マイ資産",
			menu3: "アカウントレポート",
			menu4: "アカウントセキュリティ",
			menu5: "に知らせる",
			menu6: "アクティビティセンター",
			menu7: "私の財布",
			menu8: "出金",
			walletAdd: "新しいウォレットの追加",
			report: {
				history_tit: "取引履歴レポート",
				stream_tit: "資金フロー報告",
				desc: "適切な日付範囲を選択してアカウントレポートをエクスポートしてください。（直近1年間のアカウント情報を参照できます）",
				select_date: "日付範囲の選択",
				week: "この1週間",
				month: "この1か月",
				threeMonth: "この3か月",
				sixMonth: "この半年",
				time: "時間",
				view_report: "レポートの表示",
				email_send: "電子メールで送信",
				dialog_tit: "アカウントレポート",
				dialog_p1: "FPMkets Holding Ltdはケイマン諸島に登録され（SIB番号：1612446）、取引主体として取引を処理します。",
				tit2: "Mitade取引履歴レポート",
				tit4: "Mitade資金フロー報告",
				tit3: "アカウント情報",
				label1: "アカウント名",
				label2: "アカウントID",
				label3: "アカウント通貨",
				label4: "レポート時間",
				label5: "報告期間",
				label6: "資金の流れ",
				empty: "あなたはすでに平倉取引をしていません",
				email_des: "レポートがメールボックスに送信されます",
				tab1: "レポート作成",
				tab2: "日割計算書",
				tab3: "月次決算書",
			},
			account: {
				tit1: "ようこそFPMarketsへ",
				tit2: "実際の入金取引を行うための認証の完了",
				p1: "リアル取引アカウントの開設",
				p2: "認証",
				btn1: "認証",
				tit3: "個人情報",
				label_name: "名前",
				label_email: "メールボックス",
				label_address: "住所",
				tit4: "アカウント情報",
				label_account: "取引口座番号（リアル取引）",
				label_currency: "基本通貨（リアル取引）",
				label_company: "現預金会社",
				company_tip: "FPMarkets Holding Ltd，FPMarkets Holding Ltd FPMarketsグループ傘下の会社は、ケイマン諸島金融管理局（CIMA）の許可と許可を得ている。",
				tit5: "リアル取引アカウントの開設",
				tit6: "完全なデータ",
				tit7: "基本情報の入力",
				tit8: "リスク評価",
				tit9: "取引リスク引受能力の評価",
				tit10: "認証",
				tit11: "規制要件に基づく認証の完了",
				p3: "次のいずれかの証明ファイルを選択して検証してください",
				type1: "身分証明書",
				type2: "運転免許証",
				type3: "パスポート",
				p4: "私は提供されたすべての情報とファイルが完全で、真実で正確であることを確認し、もし情報が更新されたら、30日以内にFPMarketsに更新情報を提供することを約束します。",
				next: "次のステップ",
				btn2: "資格認定に合格しました",
				modify_personal_tit: "個人情報の変更",
			},
			activity: {
				tit: "アクティビティセンター",
				desc: "イベントに参加してインセンティブを受け取り、投資収益を高めることができます",
			},
			capital: {
				real_trade: "実際の取引",
				label_nowAccount: "現在のアカウント",
				label_tradeAccount: "取引口座番号",
				label_currency: "基本通貨",
				deposit_withdraw_record: "入出金記録",
				capital_flow_details: "資金流出明細",
			},
			notification: {
				tit: "通知の設定",
				tit1: "通知チャネルを開く",
				email: "メールボックス",
				message: "ショートメッセージ",
				push: "プッシュ",
				verify: "検証",
				verified: "検証済み",
				p1: "次の通知チャネルをオン/検証してください。重要なメッセージは見逃さないでください！",
				type1: "販売クラス",
				tit2: "マーケティング活動の通知",
				desc2: "プラットフォームの特典情報、運営活動などの情報",
				tit3: "オペレーションアドバイザ",
				desc3: "豊富で専門的な知識データ",
			},
			security: {
				tit: "パスワード",
				isSet: "設定済み",
				set: "デセット",
				verify: "検証",
				verified: "検証済み",
				tit1: "セキュリティ検証",
				email: "メールボックス",
				phone: "携帯番号",
				phone_tit: "携帯電話番号のバインド",
				password_tit: "パスワードの変更",
			},
		},
		market: {
			hot: "人気がある",
			prev: "前のページ",
			next: "次のページ",
			line: "タイムシェアリング",
			main_subplot: "マスタ副図定員",
			main: "マスタ図の定員",
			subplot: "副図定員",
			go_trade: "トランザクションページにジャンプ",
		},
		news: {
			tip: "免責声明：上記の情報は一般情報であり、参考に供するだけで、取引の決定根拠としない。",
		},
		trade: {
			state1: "持倉",
			state2: "オーダー",
			state3: "履歴",
			state: "ステータス",
			state4: "平倉になった",
			state5: "元に戻す",
			type: "を選択してオプションを設定します。",
			type1: "すべて",
			type2: "売り出す",
			type3: "購入する",
			left_label1: "自選",
			left_label2: "最近のブラウズ",
			left_type1: "コンパクトカラムモード",
			left_type2: "ゆとり列モード",
			left_type3: "ポテンシャル図を表示",
			all: "すべて",
			right_bp: "昨日受け取りました",
			right_sp: "今収",
			right_height: "最高",
			right_low: "最小",
			remind_tit: "新規リマインダ",
			remindList: "リマインダリスト",
			remind_btn: "新規リマインダ",
			right_tab1: "取り引き",
			right_tab2: "詳細",
			right_sell: "売り出す",
			right_buy: "購入する",
			right_type: "を選択してオプションを設定します。",
			right_type1: "市価表 ",
			right_type2: "オーダー ",
			right_priceMoney: "掛金単価",
			right_pointerNumber: "数量（手）",
			right_lever: "レバー",
			right_balance: "使用可能残高",
			right_stop_profit: "しめきり",
			right_stop_loss: "そんしつ",
			right_profit_loss: "に利益を与える",
			now_order: "今すぐ注文",
			confirm_order: "注文の確認",
			right_tit1: "取引戦略",
			right_rinei: "日内",
			right_short: "短期",
			right_middle: "中間",
			right_time: "リリース時間",
			right_tit2: "代替ポリシー",
			right_tit3: "科学技術評価",
			right_tit4: "データ＃データ＃",
			right_tit5: "取引気分",
			right_label_sell: "売り手",
			right_label_buy: "買い手",
			right_tip: "参考までに本司の立場としない",
			right_tip2: "15分ごとに更新",
			right_tit6: "価格上昇の下落幅",
			minute: "分",
			today: "今日",
			right_tit7: "価格変化区間",
			now_price: "現在の価格",
			right_low_price: "最低価格",
			right_height_price: "最高価格",
			right_tit8: "契約情報",
			right_tit9: "豪ドルはレイランに対して",
			right_label1: "単一取引数量",
			right_label2: "さいだいてこ",
			right_label3: "契約サイズ",
			right_label4: "最大総在庫数",
			right_label5: "変動価格差",
			right_label6: "隔夜料",
			hand: "手",
			right_collection_time: "受け取り時間",
			right_tip3: "料金基準：取引規模全体から受け取る",
			right_label7: "保証金比率",
			right_label8: "保証金比率の維持",
			right_label9: "取引期間",
			right_label10: "現在の取引期間",
			right_label11: "次の取引期間",


		},
	},
	home: {
		banner_p1: "に通じをつける",
		banner_p2: "プラットフォーム取引",
		banner_p3: "外国為替、商品、指数、株式",
		banner_p4: "その他の人気金融商品",
		banner_p5: "世界で最も人気のある金融市場を探ることができます。FPMarketsは、0コミッション、極めて優位な低価格差、および柔軟なレバレッジ構成を提供します。",
		hot: "人気がある",
		tit1: "より友好的な取引",
		p1: "シンプルで直感的なインタフェース、簡単な操作",
		p2: "Webページ、携帯アプリ、デスクトップをサポートし、取引は指先に尽くしている",
		p3: "ストップロス/トラッキングストップロスなどのリスク管理ツール",
		p4: "複数の科学技術チャートと財経カレンダー、インスタントニュース",
		p5: "リアルタイムメール、メール、プッシュ通知",
		p6: "継続的に改善し、より良い取引体験を実現する",
		btn1: "プラットフォームの探索",
		row3_tit: "いつでもどこでも、自由取引",
		row3_tip: "Webページ、携帯アプリ、デスクトップをサポートし、取引は指先に尽くしている",
		row3_tit2: "スキャンコードのダウンロード",
		row3_tit3: "テーブル端",
		row3_tit4: "いどうたんし",
		regular_tit: "信頼できる国際プラットフォーム",
		regular_tip: "私たちはお客様に安全で責任ある取引環境を提供することに力を入れています。。",
		regular_tit1: "権威機関の監督管理",
		regular_p1: "世界中のお客様から信頼を得るための業界の権威ある規制と権限",
		regular_tit2: "資金の安全性の保護",
		regular_p2: "小売顧客の預金は、規制規制の要件に従って必要に応じて信託口座に個別に保管されます。",
		regular_tit3: "負数残高保護",
		regular_p3: "口座のマイナス残高保護を提供し、マイナス残高はタイムリーにゼロにし、損失が入金額を超えず、安心して取引できるようにします",
		regular_tit4: "5*24時間対応カスタマーサービス",
		regular_p4: "24時間のオンラインサポートにより、お客様の問題を解決するための誠実でプロフェッショナルなカスタマーサービスチーム",
		service_tit: "より多くのサービスを提供",
		service_tit1: "取引戦略",
		service_p1: "リアルタイム取引戦略は、最新市場の動向を理解し、取引のタイミングをよりよく把握するのに役立ちます。",
		service_tit2: "取引の知識",
		service_p2: "FPMarketsとともに、取引の知識を無料で学び、取引スキルを向上させます。",
		service_tit3: "リスク管理",
		service_p3: "FPMarketsが提供する無料のリスク管理ツールを理解し、資産をより保護します。",
		step_tit: "口座開設が簡単で便利",
		step_tip: "簡単な3ステップで数分でアカウントをオープン",
		step_tit1: "登録",
		step_p1: "情報を記入してお申し込みを提出してください",
		step_tit2: "入金",
		step_p2: "さまざまな方法で迅速に資金を預けることができます",
		step_tit3: "取引開始",
		step_p3: "取引機会の発掘と迅速な発注",
		award_tit: "私たちの成績",
		award_p1: "私たちは常に卓越性を追求し、精進し、お客様に良質な取引サービスを提供することに力を入れています。",
		award_p2: "FPMketsブランドは業界内の有名な機関から賞を受賞し、チームの努力と顧客への負担を肯定したことを光栄に思っています。",
		row7_tit: "リアルタイムのグローバル金融情報",
		row7_tit1: "インスタントニュース",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "ダウンロード",
		p1: "いつでもどこでも取引する",
		p2: "より迅速でスマートな取引",
		p3: "0コミッション取引",
		p4: "世界で最も人気のある投資品種",
		renderDom1: "外国為替",
		renderDom2: "米株",
		renderDom3: "金",
		renderDom4: "げんゆ",
		renderDom5: "しすう",
		renderDom6: "暗号化通貨",
		login: "ログイン",
		elail: "メールボックス",
		phone: "携帯番号",
		no_account: "アカウントがありません？",
		go_register: "登録解除",
		register: "登録",
		now_register: "今すぐ登録",
		have_account: "既存のアカウント？",
		go_login: "ログイン解除",
	},
	about: {
		serviceFee: "サービス料金",
		introduction: "プラットフォームの紹介",
		trade_platform: "取引プラットフォーム",
		complaint_process: "クレームプロセス",
		page: "ページ",
		place_search: "知りたいことを検索",
		no_answer: "必要な答えが見つかりません?",
		contact_us: "連絡先",
		awards: {
			h1: "奨励及び栄誉",
			p1: "私たちは常に卓越性を追求し、精進し、お客様に良質な取引サービスを提供することに力を入れています。FPMketsブランドは業界内の有名な機関から賞を受賞し、チームの努力と顧客への負担を肯定したことを光栄に思っています。",
			p2: "，または、デルが受賞しているオンライン取引プラットフォームを体験することができます。"
		},
		charges: {
			h1: "料金と料金",
			p1: "FPMarketsは、顧客エクスペリエンスを向上させるための構成要素として、顧客により優れた取引サービスを提供することを望んでいる。私たちは費用と料金について、隠し料金を含まずに公開透明性を維持することに力を入れています。お客様の主な取引コストは、売買価格差と夜間の持倉利息です。",
			h2: "まず、FPMarketsを使用したトランザクションを楽しむことができます",
			li1: "インスタント製品見積もり",
			li2: "専門技術指標",
			li3: "独占市場分析",
			free: "無料",
			view: "をクリックしてから、",
			h3: "売買価格差",
			p2: "FPMarketsは、サービス補償として売買価格差を受け取る。この部分の費用は製品見積に反映されており、倉庫を構築している間に実際に差額が支払われています。差額費用は固定ではないため、特定の製品見積ページにアクセスするか、",
			h4: "預金と引き出し料金",
			p3: "お客様のコストを削減するために、私たちはほとんどの場合、お客様のアクセス料金を請求しません*。ただし、協力会社や銀行は、お客様がアクセスする際に手数料、例えば中継銀行料金を受け取ることがあります",
			p4: "料金が必要な場合はカスタマーサービス担当者にお問い合わせください。",
			p5: "例えば、国際クレジットカード取引、銀行口座振替/振替、未サポート通貨を用いた取引（外貨換算）など",
			h5: "夜間持倉コスト",
			p6: "グリニッジ時間の夜10時（GMT 22:00）に倉庫を持っている場合は、夜間料金を請求/預入れます。各製品に関する夜間料金照会は、特定の製品見積ページに、またはログインすることができます。",
			h6: "その他の費用",
			p7: "その他の費用はありません。私たちの料金政策は完全に透明で、料金を徴収する必要がある項目は事前に公表され、隠れた料金を心配する必要はありません。"
		},
		contact: {
			h1: "連絡先",
			address: "アドレス",
			custom_email: "お客様連絡メール",
			business_email: "ビジネス提携メール",
			h2: "私たちに手紙を書いてください。",
			p1: "私たちのサービスについて何か質問があれば、次の表に記入して質問を提出してください。私たちのカスタマーサービス担当者はできるだけ早く返事をします。また、Trading Centralとの提携によって提供される毎日の取引戦略に応募するには、次の表のタイプで「毎日市場分析の購読」を選択し、希望する言語バージョンを記入してください。登録します。*サブスクリプションサービスは、既存のFPMketsの実際の顧客にのみ適用されます。",
			h3: "プログラマブル",
			p2: "すべての苦情については、専門チームが調査して解決します。詳細は",
		},
		faq: {
			h1: "よくある質問",
			tit1: "シミュレーション勘定科目のメリット？",
			tit2: "実際のアカウントを開く方法？",
			tit3: "価格表の作成方法？",
			tit4: "どうして私は注文できないのですか。？",
			p1: "シミュレーション勘定科目と実際の勘定科目は、取引インタフェース、データ、操作のほとんどが同じで、シミュレーション勘定科目には5万ドルの仮想資金があり、お客様に資金リスクがない場合にシミュレーション操作を通じてプラットフォームの各機能を熟知させることを目的としています。",
			p2: "実際のアカウントを開くには、次の手順に従います。：",
			p3: "1.FPMarkets登録ページに入り、ヒントに従って：メールボックス/携帯電話番号を登録し、ログインパスワードを設定し、確認を提出してから自動的に【シミュレーションアカウント】を作成する。",
			p4: "2.シミュレーションアカウントページで、「リアルアカウントに切り替え」を選択し、プロセスに従って操作すれば【リアルアカウント】を開設できます。",
			p5: "実際のアカウントを開設する前に、取引に関する法律開示ファイルを読むことをお勧めします。",
			p6: "実際のアカウントに入金できるのは、認証が完了した後だけです。",
			p7: "「取引」（Trade）をクリックすると、プラットフォームが取引を提供できるすべての製品が表示されます。右側の入力ボックスに品種コードまたは名前を入力して検索し、「購入/販売」（SELL/BUY）をクリックすると取引ウィンドウがポップアップされ、取引ウィンドウには現在の価格と予想必要な保証金が表示されます。手動でオープントランザクションの数を調整し、ストップロスを設定してリスク制御を行い、下の「購入/売却」（SELL/BUY）をクリックするとオープンを完了できます。すべての金融製品の価格は市場の変動や更新に伴い、購入/販売ボタンを押す前に相場が変化している可能性があることに注意してください。",
			p8: "まず、ネットワーク接続が正常かどうかを確認してください。取引品目市場が一時的に閉鎖されたり、休市されたりすると、注文できなくなります。詳細については、オンラインカスタマーサービスにお問い合わせください。",

		},
		fundSecurity: {
			h1: "資金セキュリティ",
			p1: "FPMarketsは権威ある規制当局の許可と規制を受けている。コンプライアンスは私たちの仕事の核心であり、文化でもあります。お客様の資金の安全性は極めて重要であり、すべてのお客様の預金は隔離されて保管されています。",
			p2: "小売顧客の預金は、規制当局の要件に従って必要に応じて信託口座に個別に保管されます。",
			p3: "FPMarketsは、法的規制の許諾以外のビジネス活動にはお客様の資金を使用しません。",
			p4: "FPMarketsはどの市場でも投機活動をしない",
			p5: "協力メーカー会計士事務所独立監査",
		},
		help: {
			h1: "FPMarketsヘルプセンターへようこそ",
		},
		media: {
			h1: "メディアセンター",
			p1: "FPMarketsブランドに関する情報、プレスリリース、メディアのクローズアップ、発表、その他の情報を表示します。",
		},
		mitrade: {
			h1: "FPMarketsについて",
			view_certificate: "（検証方法）",
			p1: "FPMarketsは、権威ある機関が権限を与えて規制する金融科学技術会社であり、投資家に簡単で便利な取引体験を提供することに専念している。革新的なFPMarkets多元化取引プラットフォームは、最適なモバイル取引プラットフォーム、最も革新的な証券会社などの賞を何度も獲得している。",
			p2: "FPMarketsプラットフォームを通じて、株式、指数、商品、外国為替など数百の人気品種を含む、より広範な国際金融市場に投資、取引することができます。FPMarketsは、フレンドリーな携帯電話版とWeb版の取引プラットフォームを体験するだけでなく、競争力のある取引コスト、迅速な取引実行、優れたカスタマーサービス、豊富な情報サポートを提供し、投資、取引の好機をタイムリーに把握するのに役立ちます。",
			p3: "MFMarketsは業界の権威、多機関から厳しい規制を受けている。MFMarkets Internationalはモーリシャス金融サービス委員会（FSC）の権限を得て規制を受けており、ライセンス番号はGB 20025791である。モーリシャスのライセンスの詳細については、FSC公式サイトを参照してください。https://www.fscmauritius.org",
			p4: "をクリックしてから、MFPMarkets Globalは、オーストラリア証券投資委員会（ASIC）が認可した金融サービスライセンス（AFSL 398528）を保有している。オーストラリアのライセンスの詳細については、ASIC公式サイトwww.asic.gov.auまで",
			p5: "をクリックしてから、itrade Holdingはケイマン諸島金融管理局（CIMA）の権限を得て規制を受けており、SIBライセンス番号は1612446である。ケイマンライセンスの詳細については、CIMA公式サイトwww.cima.kyまで",
			p6: "をクリックしてから、FPMarketsのすべての業務運営は厳格な監督管理の下で行われ、すべての規定に従っている。",
			h2: "MFMarketsの背景",
			p7: "FPMketsは、オーストラリアのメルボルンで設立された金融取引と金融テクノロジー業界の経験と知識に富んだベテランチームで構成されています。FPMarketsの理念は、取引がより簡便に、よりフレンドリーになること",
			p8: "デルの全体化戦略とビジネスモデルにより、業界の発展と市場ニーズに対してグローバルな深い観察と認識を持つことができ、それにより、より的確な資源の配置、絶えず科学技術の革新と効率の最適化、持続的に顧客により便利で、より友好的な取引体験をもたらすことができます。​",
			h3: "FPMarketsを選択した理由",
			why1: "敷居の低い取引条件",
			why2: "業界の権威による規制",
			why3: "シンプルで直感的な取引プラットフォーム",
			why4: "ハイレベルなオンラインサポート",
			why5: "競争力のある取引コスト",
			why6: "負数残高保護",
			h4: "賞と栄誉",
			p9: "私たちは常に卓越性を追求し、精進し、お客様に良質な取引サービスを提供することに力を入れています。",
			p10: "FPMketsブランドは業界内の有名な機関から賞を受賞し、チームの努力と顧客への負担を肯定したことを光栄に思っています。",
			new_add: "グローバルな新規ユーザー",
			ol1: "最適なニュースおよび分析情報提供者",
			ol2: "アジア最高の外国為替証券商",
			ol3: "オーストラリア外国為替顧客満足大賞",
			ol4: "最適なモバイルアプリケーション",
			ol5: "オーストラリアで最も急速に成長している証券会社",
			h5: "FPMarketsの運営理念",
			tip_tit1: "責任",
			tip_tit2: "簡潔で美しい",
			tip_tit3: "透過性",
			tip_tit4: "イノベーション",
			tip_p1: "すべての取引はリスクに関連しており、FPMarketsはより多くの措置をとり、顧客に責任ある取引環境を提供している。FPMketsは業界の権威、多機関の厳格な監督管理を受け、高レベルの安全と安定性運営基準に従う。デルのプラットフォームは、リスク管理を含む複数の責任ある取引ツールを提供し続けています。",
			tip_p2: "シンプルさは美しさであり、細部の成果は卓越しており、FPMarketsは使いやすさとユーザー体験のすべての詳細を求めています。私たちはプラットフォームとサービスを最適化し続け、初心者でも経験豊富な投資家でも、FPMarketsプラットフォームを通じて取引の好機を簡単に把握できるようにしたいと考えています。",
			tip_p3: "FPMarketsは、公正で効率的な取引環境の恩恵を十分に受けるために、厳格で透明な自律的な規制システムに従い、全面的で透明なプラットフォーム製品情報、価格環境、データを提供し、すべての費用を明確に公開し、サービスプロセス全体の完全に誠実で透明性を確保します。",
			tip_p4: "FPMketsは、科学技術の進歩、金融革新、より広範なサービスこそが成長を推進する力であり、価値を創造し、共有することができると信じている。そのために私たちは開放的な思考を奨励し、持続的に革新を推進し、金融科学技術の革新者とリーダーになることを目指しています。",
			row6_tit1: "私たちの使命",
			row6_tit2: "私たちのビジョン",
			row6_tit3: "ビジネスチャンス",
			row6_p1: "より多くの取引をしたい人のために、空間、時間、市場の制限を受けない公平、高効率、透明な取引環境を提供し、業界の金融と科学技術革新をリードする。",
			row6_p2: "私たちは技術の進歩、金融革新、多元化サービスが私たちの成長を推進する力であり、私たちが社会で価値を創造し、共有し続けることができるようにすると信じています。",
			row6_p3: "そのために、私たちは開放的な思考を奨励し、革新を推進し、絶え間ない成長と進歩を通じて、",
			row6_p4: "世界をリードする外国為替企業",
			row6_p5: "私たちは7カ国にまたがるグローバルチームで、チームが急速に発展するにつれて、専門家を探して私たちに参加してきました。多様な文化のチームと一緒に好きなことをしよう！",
			row6_p6: "最新の履歴書と一緒に仕事をしたい理由をEメールで送ってください：",
			row6_p7: "。また、",
			row6_p8: "または",
			row6_p9: "を参照してください。。",
			row6_p10: "FPMarketsは、個人情報のセキュリティを厳格に保護します。応募プロセスでは、FPMarketsは財務情報を要求しません。私たちの採用には、財務関連、クレジットカード、銀行口座情報、またはいかなる形の支払いも必要ありません。",
			row6_p11: "職階に不正があると疑っている場合は、",
			row6_p12: "FPMkets人的資源部に連絡します。虚偽の求人情報にだまされていることを確認した場合は、地元の関係機関に連絡してください。",

		}
	},
	product: {
		row2: {
			tit: "取引品目",
			sell: "売り出す",
			buy: "購入する",
			trade: "取  り引き",
			more_product: "その他の取引製品",
		},
		row3: {
			tit: "複数のデバイス取引をサポート",
			tit1: "複数のデバイス取引をサポート",
			p1: "FPMarketsは、Webページ、携帯電話、およびデスクトップ取引プラットフォームを提供します。いつでもどこでも取引ができます。",
			download: "ダウンロード",
		},
		row4: {
			tit: "FPMarketsの選択",
			tit1: "シンプルで直感的なプラットフォーム",
			p1: "相場、取引、口座管理、情報、リスク管理を一体化し、機能が強く、より良い使用体験を実現する",
			tit2: "競争力のある取引コスト",
			p2: "0取引手数料、非常に競争力があり、透明な低価格差、低翌日物金利で、低コストの取引を楽しむことができます",
			tit3: "敷居の低い取引条件",
			p3: "毎回の最小取引手数料は0.01手まで低く、低保証金で倉庫を開けて取引することができる",
			tit4: "権威ある監督管理を受ける",
			p4: "業界の権威による授権と監督管理、すべての顧客の預金を個別に隔離して保管し、顧客の資産の安全を保護する",
			tit5: "負数残高保護",
			p5: "どのような市場状況でも、あなたのアカウントの損失は元金を超えず、マイナスの残高はタイムリーにゼロになり、リスク管理能力を向上させます",
			tit6: "ハイレベルなオンラインサポート",
			p6: "迅速な対応を提供するオンラインカスタマーサービスは、専門チームのたゆまぬ努力により、より質の高いサービスを提供します",
		},
		row5: {
			tit: "関連する問題",
		},
		commodities: {
			title: "商品取引",
			p: "貴金属、エネルギーなどの大口商品はインフレを防ぐことができ、投資の付加価値の機会もあり、多元化投資の重要な構成部分であると考えられている。その中で、金、白銀、石油などは取引量の大きい商品取引品種であり、公開、透明な価格及び高い流動性があり、投資家から広く歓迎されている。商品価格は需給、経済と政策情勢、通貨などの影響を受けて顕著に変動し、リスク収益の機会を提供している。",
		},
		forex: {
			title: "外国為替取引",
			p: "外国為替取引とは、ある通貨を別の通貨に変換することであり、外国為替市場は世界最大の取引量を持つ金融市場である。外国為替市場には多くの参加者がいて、外国為替市場を通じて支払い、通貨変動リスクをヘッジしたり、収益を得るために取引をしたりしています。外国為替取引は、世界中の銀行、機関、個人トレーダーの間でネットワークを介して行われ、週5日、1日24時間の価格変動により、リスク収益の機会を提供しています。",
		},
		indices: {
			title: "indices",
			p: "株価指数は特定取引所のある種の特性株を代表する統計数値であり、市場上のある種の構成株の全体的な価格水準と変動を反映する定員である。取引株価指数は、市場全体や業界全体の投資機会を反映し、投資株のリスクを減らすことができる。オーストラリア200、米国テクノロジー株100、香港50、ドイツ30など、米国、欧州、アジア、オーストラリア市場で最も人気のある指数は、世界の株式市場に異なる機会を提供している。",
		},
		shares: {
			title: "株式取引",
			p: "株式は取引所で発行される有価証券です。企業は資金調達のため、上場取引所を通じて会社の所有権を証券を利用して分配する。2019年だけでも、世界の株式の取引総額は60兆ドルを超え、規模が大きく、流動性が強いため、長年にわたって経験しても、世界の投資家の目に最も人気のある金融ツールの1つである。FPMarketsプラットフォームでは、トレーダーはレバレッジを利用して株式の価格変化を取引することができる。株価は全体的な経済、産業の見通し、会社の運営など多くの要素の影響を受け、変動性が大きく、常に投資取引の機会が存在する。",
		},
		coins: {
			title: "暗号化された通貨",
			p: "暗号化通貨は、コードによって作成されたデジタル通貨です。それらは伝統的な銀行と政府システムの範囲外で自主的に運営されている。その名の通り、暗号化通貨は暗号化技術を使用して取引の安全を保証し、他の組織の作成を規定し、その中でビットコインは最も原始的で現在まで最も有名な暗号化通貨であり、ビットコインは中本聡によって作成され、2009年1月に発売された。今日になっても、中本聡という名前は一人なのかグループなのかは未知のままだ。ビットコインは暗号化通貨を中心化する最初のものと考えられている。暗号化されたすべての通貨のように、分散共通元帳として使用されるメモリブロックチェーン取引データベースを介して制御されます。現在では、1000以上の暗号化された通貨がオンラインで取引されています。"
		}
	},
	trade: {
		data_sources: "データソース",
		tip: "上記の情報は参考にしてください。FPMarketsは情報コンテンツの正確性、リアルタイム性、完全性を保証するものではありませんので、提供された情報に依存しすぎてはいけません。この情報には、デルの取引価格記録、または金融ツール取引の契約や誘致は含まれていません。FPMarketsは、財務上のアドバイスを提供する会社ではなく、コマンドの性質を実行するサービスのみを提供しています。読者自身が投資に関するアドバイスを求めることをお勧めします。完全な免責事項については、「」を参照してください。",
		tip2: "毎週金曜日15:00（GMT）更新",
		tip3: "取引の観点は市場の取引感情を予測するツールであり、各ヒット製品に対する業界専門家の短期的・中期的な見方とトレンド予測を反映している。",
		analysis: {
			tit: "取引戦略",
			all: "すべて",
			day: "日内",
			tit1: "取引戦略",
			tit2: "科学技術評価",
		},
		calendar: {
			tit: "財経カレンダー",
			prev_week: "先週",
			next_week: "来週",
			place_date: "検索日",
			select: "オプション",
			select1: "国",
			select2: "重要性",
			start_time: "開始時間",
			end_time: "終了時間",
			search: "検索けんさく",
		},
		contract: {
			tit: "契約細則",
			type1: "すべて",
			type2: "外国為替",
			type3: "商品",
			type4: "しすう",
			type5: "米国株",
			type6: "オーストラリア株式",
			tit1: "主要外貨",
			tit2: "二次外貨",
			tit3: "商品",
			tit4: "しゅしすう",
			tit5: "にじしすう",
			tit6: "米国株",
			tit7: "オーストラリア株式",
			tit8: "共通ルール",
			p1: "こうへいひれい",
			p2: "請求書有効期間",
			p3: "制限なし",
			p4: "夜間料金精算時間",
			p5: "毎日決済する夏：05：00、冬：06：00",
			p6: "レバー、夜間割増料金などの情報",
			p7: "取引プラットフォームを参照",
			p8: "上記はすべての取引品目に適用されます",
			tit9: "取引カレンダー",
			p9: "休市",
			p10: "以上の取引時間は祝日や市場の特別調整の影響を考慮していない。",
			p11: "具体的な取引時間の提案",
			p12: "ログインプラットフォーム",
			p13: "，関連品種の概要を見る。",
		},
		forecast: {
			tit: "取引の観点",
			p1: "平均値",
			p2: "強気",
			p3: "弱気",
			p4: "よこばん",
			p5: "傾向",
		},
		news: {
			tit: "インスタントニュース",
		},
		platforms: {
			tit: "FPMarkets取引プラットフォーム",
			p: "シンプルで直感的なインタフェース、優れたトランザクションパフォーマンスにより、より多くの投資家のニーズに対応します。今日はFPMarketsで取引を始めましょう。",
			tit1: "便利で使いやすいFPMarkets取引プラットフォーム",
			tit2: "いつでもどこでもFPMarkets Appで取引",
			li1: "数百件のヒット市場を取引し、資金の安全を保証する権威ある規制",
			li2: "市場の動向を即時に監視し、取引分析を確認し、最新の財経ニュースを閲覧する",
			li3: "さまざまなタイプのトランザクションチャートをいつでも表示できます。支障なく図面機能を適用できます。",
			li4: "アンドロイドまたはiOSデバイスでFPMarketsの認定を受けた取引プラットフォームを体験",
			code_download: "スキャンコードのダウンロード",
			tit3: "ダウンロード不要、ブラウザ経由でオンライン取引",
			li5: "ダウンロード不要、PC側ブラウザでオンライン取引",
			li6: "しばしば受賞：2022年アジア最優秀外国為替ブローカー、2022年オーストラリア最優秀取引プラットフォーム、2022年世界最速の外国為替金融科学技術ブローカー",
			li7: "強力な技術指標を提供：MACD、KDJ、RSI、TRIX、DMA、CCIなど",
			li8: "ストップロスやマイナス残高保護などのリスク管理ツールを搭載し、取引の潜在的なリスクを軽減",
			tit4: "お客様のデスクトップ取引に最適なソリューション",
			li9: "フリーリストの市況を簡単に見る",
			li10: "価格変化の通知やその他の情報通知を受信するためのリマインダ機能を有効にする",
			li11: "さまざまなグラフレイアウトにより、異なる時間次元から市場の動きを追跡できます",
			li12: "契約仕様、取引感情、取引分析はすべて同じインタフェースであり、切り替えを必要としない",
			li13: "FPMarketsで最新の取引ニュースを直接入手する",
			tit5: "FPMarkets取引プラットフォームを選択した理由",
			row5_p1: "安全保障",
			row5_p2: "0コミッション",
			row5_p3: "マルチプラットフォームサポート",
			row5_p4: "科学技術図表",
			row5_p5: "いつでもどこでもアクセス",
			row5_p6: "各種図面ツール",
		},
		quotes: {
			tit: "リアルタイム見積もり",
			p1: "今話題の金融銘柄の即時オファー、市場のセンチメント、関連ニュースを提供します。",
			hot: "人気がある",
		},
		risk: {
			tit: "リスク管理",
			p1: "すべての取引はリスクに関連しており、FPMarketsが提供する無料リスク管理ツールを活用して、市況の順逆にかかわらず、リスクを効果的に制御することができる。",
			function_tit1: "締まり・止損機能",
			function_tit2: "トラッキングストップ機能",
			function_p1: "利益のロック",
			function_p2: "損失の制限",
			function_p3: "ロック利益の極大化",
			function_p4: "倉庫の自動監視が不要",
			function_p5: "損失平倉価格の調整",
			row2_tit1: "締まり・止損機能",
			row2_p1: "新規注文を作成したり、既存の注文を修正したりするときは、「利益停止」と「損失停止」を設定することができます。設定後、注文は通常、設定した目標価格に基づいて平倉化され、目標に到達したときに利益をロックしたり、市場の動きが不利なときに損失を減らすのに役立ちます。どの注文も市況変動によって跳ね返る可能性があり、デフォルト価格では注文を実行できませんが、目標価格から次の最も有利な価格で平倉化されます。",
			row2_tit2: "例",
			row2_p2: "ユーロ/ドルの現在価格は1.13816/1.13837（売る/買う）です。1.13837で1手（1手＝100000ユーロ）の購入注文書を作成し、1.13806でストップウォッチを設定します。",
			row2_p3: "一般的には、価格が1.13806に下落すると、ストップウォッチがトリガーされ、1.13806の価格で平倉化され、合計31ドルの損失が発生します。",
			row2_p4: "しかし、市場環境が急変し、価格が1.13837から1.13795に直接下落し、あなたのストップロス目標を直接スキップした場合、市場はジャンプし、システムは1.13806でフラット倉庫を行うことはできませんが、次の最も有利な価格、つまり1.13795でフラット倉庫を実行し、最終的な損失は42ドルになります。",
			row3_tit1: "トラッキングストップ機能",
			row3_tit2: "例",
			row3_p1: "追跡停止（移動停止）機能が強く、停止価格は利益に応じて自動的に変化し、常に倉庫を監視する必要がなく、利益のロックや損失の削減を極大化することができます。新規注文の場合は、追跡停止ポイントを設定するだけで、価格が有利な方向に向かって歩くと、最新の価格に基づいて自動的に更新されます。逆に、価格が不利な方向に変動すると、ストップロス注文が開始され、設定した損失点数の距離で平倉になります。どの注文も市況変動によって跳ね返る可能性があり、デフォルト価格では注文を実行できませんが、目標価格から次の最も有利な価格で平倉化されます。",
			row3_p2: "ユーロ/ドルの既存価格は1.13816/1.13837（売る/買う）。1.13837で1手の購入注文書を作成し、100ポイント（0.00100）の追跡停止を設定します。",
			row3_p3: "製品価格が1.13816の場合、ストップロス価格は1.13716です。製品の販売価格が1.13845に上昇すると、ストップロス価格は設定した距離で更新され、更新されたストップロス価格は1.13745です。",
			row3_p4: "逆に、製品価格が1.13845から1.13745に下落すると、追跡停止がトリガーされ、1.13745で平倉を実行します。",
			tip: "ポイント：通常、金融機器価格の最小変動組織をポイントと呼ぶ。FPMarketsプラットフォームでは、ツール価格の最後のビット数または小数点を指します。",
		},
		sentiment: {
			tit: "情緒指数",
			type1: "すべて",
			type2: "外国為替",
			type3: "商品",
			type4: "しすう",
			long: "マルチヘッド",
			short: "からっぽ",
		},
	},

	layout: {
		aside: {
			nav1: "取り引き",
			nav2: "相場",
			nav3: "情報＃ジョウホウ＃",
			nav4: "学び舎",
			nav5: "マイ",
			newsDialog: {
				title: "メッセージセンター",
				type1: "システム通知",
				type2: "公告",
				allRead: "すべて既読としてマーク",
			},
			settingDialog: {
				title: "設定＃セッテイ＃",
				nav1: "標準",
				nav2: "表示プリファレンス",
				nav3: "システム情報",
				logout: "ログアウト",
				setting1: "言語",
				setting2: "取り引き",
				setting2_tip: "オープン品種自動追加オプション",
				setting3: "フィードバック",
				setting3_tip: "機能の推奨事項",
				setting4: "実名認証",
				setting4_tip: "実名認証",
				setting5: "トピック・モード",
				setting5_label1: "ダークカラー",
				setting5_label2: "明るい色",
				setting6: "上げ下げ色",
				setting6_label1: "緑が増し赤が落ちた",
				setting6_label2: "赤くなったり下がったりする.",
				setting7: "グラフの設定",
				setting7_label1: "標準版",
				setting7_label2: "TradingView版",
				setting8: "声明と協定",
				setting8_label1: "プライバシーポリシー",
				setting8_label2: "製品開示ステートメント",
				setting8_label3: "リスク開示声明",
				setting8_label4: "顧客協定",
				feedback_title: "機能の推奨事項",
			}
		},
		footer: {
			hot: "人気品種",
			sort: "品種",
			code: "コード＃コード＃",
			buy_price: "買値",
			sell_price: "売値",
			chg: "上昇幅",
		},
		header: {
			place_search: "取引品目の検索",
			balance: "使用可能残高",
			balance_tip: "使用可能残高:新規倉庫ポジションの開設に使用できる勘定科目の金額",
			profit_loss: "に利益を与える",
			asset_view: "資産の概要",
			netValue: "ネット",
			netValue_tip: "純価値：現在のアカウント価値（すべての在庫の損益を含む）",
			marginLevel: "保証金水準",
			margin: "デポジット",
			margin_tip: "保証金：開設と維持のための倉庫保有に必要な金額",
			maintainsMargin: "維持保証金",
			maintainsMargin_tip: "保証金の維持：すべての倉庫を保有している場合、口座内で維持する必要がある最低保証金金額。",

		},
	},
	table: {
		label_name: "の名前をあげる",
		label_buyPrice: "買い取り価格",
		label_sellPrice: "売値",
		label_variableValue: "へんどうち",
		trade: "取り引き",
		label_code: "製品コード",
		label_title: "の名前をあげる",
		label_start: "重要性",
		label_country: "国",
		label_event: "イベント＃イベント＃",
		label_previous: "ぜんち",
		label_predictive: "予測値",
		label_announced: "公開値",
		label_stop_loss_min: "ストップロス単最小距離",
		label_difference: "動的価格差",
		label_contract_size: "契約サイズ",
		label_max_hand: "最大1取引手数料",
		label_min_hand: "最小単一取引手数料",
		label_trade_week: "取引カレンダー",
		label_week: "1週間",
		label_month: "1月",
		label_quarter: "1四半期",
		label_dailyTrend: "一日の動き",
		label_tradeId: "取引ID",
		label_breed: "品種",
		label_tradeType: "取引タイプ",
		label_currency: "通貨＃ツウカ＃",
		label_amount: "金額",
		label_balance: "残高",
		label_type: "を選択してオプションを設定します。",
		label_time: "時間",
		label_orderNumber: "注文番号",
		label_pointer_number: "手の数",
		label_price_money: "オープン価格",
		label_stop_win_price: "しめきり",
		label_stop_lose_price: "そんしつ",
		label_openTime: "開倉時間",
		label_profit_loss: "に利益を与える",
		label_sell_buy: "売値/買値",
		label_chg: "上昇幅",
		label_sell_price: "売値",
		label_buy_price: "買値",
		label_condition: "条件＃ジョウケン＃",
	},
	form: {
		label_feedback: "質問とアドバイス",
		place_feedback: "質問やアドバイスを入力してください",
		label_img: "画像",
		label_img_tip: "選択、提供問題スクリーンショット選択、提供問題スクリーンショット",
		feedback_tip: "お急ぎのご質問がございましたら、お問い合わせください",
		online: "オンラインカスタマーサービス",
		label_name: "名前",
		place_name: "名前を入力してください",
		label_email: "メールボックス",
		place_email: "メールアドレス",
		message_email: "メールアドレスを入力してください",
		label_nationality: "国籍",
		place_nationality: "国籍を入力してください",
		place_questionType: "質問タイプ",
		message_questionType: "タイプを選択してください",
		questionType0: "取引プラットフォーム",
		questionType1: "製品と料金",
		questionType2: "毎日市場分析の購読",
		questionType3: "カスタマーサービス",
		questionType4: "その他",
		place_question: "あなたの質問",
		message_question: "質問を入力してください",
		submit_question: "質問の送信",
		label_phone: "携帯番号",
		place_phone: "携帯電話番号を入力してください",
		message_phone: "携帯電話番号を入力してください",
		label_password: "パスワード",
		place_password: "パスワードを入力してください",
		message_password: "パスワードを入力してください",
		label_confirmPassword: "パスワードの確認",
		place_confirmPassword: "パスワードを再入力してください",
		message_confirmPassword: "パスワードを再入力してください",
		label_captcha: "認証コード",
		place_captcha: "認証コードを入力してください",
		message_captcha: "認証コードを入力してください",
		get_captcha: "認証コードの取得",
		label_inviteId: "招待コード",
		place_inviteId: "招待コード（選択）",
		to: "終了",
		start_time: "開始時間",
		end_time: "終了時間",
		label_cardNumber: "証明書番号",
		place_cardNumber: "証明書番号を入力してください",
		message_cardNumber: "証明書番号を入力してください",
		label_cardMain: "証明書の正面",
		message_cardMain: "証明書の正面を入力してください",
		label_cardBack: "証明書の裏面",
		message_cardBack: "証明書の裏面を入力してください",
		confirm_modify: "変更の確認",
		label_realName: "フルネーム",
		place_realName: "名前を入力してください",
		message_realName: "フルネームを入力してください",
		label_firstName: "名前",
		place_firstName: "姓を入力してください",
		message_firstName: "姓を入力してください",
		label_lastName: "の名前をあげる",
		place_lastName: "ミドルネームを入力してください",
		message_lastName: "名前を入力してください",
		label_birthday: "誕生日」、",
		place_birthday: "生年月日を入力してください",
		message_birthday: "生年月日を入力してください",
		place_nowPsd: "現在のパスワードを入力してください",
		message_nowPsd: "現在のパスワードを入力してください",
		place_newPsd: "新しいパスワードを入力してください",
		message_newPsd: "新しいパスワードを入力してください",
		place_crmPsd: "新しいパスワードを確認してください",
		message_crmPsd: "新しいパスワードを確認してください",
		label_breed: "品種",
		place_breed: "品種を入力してください",
		label_phase: "当",
		label_buyPrice: "買値",
		label_sellPrice: "売値",
		label_do: "のために",
		label_height: "より高い",
		label_low: "より下",
		label_equal: "または等しい",
		labelWalletName: "財布の名前",
		messageWalletName: "財布の名前を入力してください",
		placeWalletName: "財布の名前を入力してください",
		labelWalletAddress: "ウォレットアドレス",
		messageWalletAddress: "ウォレットアドレスを入力してください",
		placeWalletAddress: "ウォレットアドレスを入力してください",
		labelAmount: "金額",
		messageAmount: "金額を入力してください",
		placeAmount: "金額を入力してください",
		placeTimeLimit: "時間制限を入力してください",
		messageTimeLimit: "時間制限を入力してください",
	},
	pay: {
		title: "入出金が便利である",
		p1: "迅速な出入金をサポートする複数の一般的な支払チャネル",
		p2: "お客様の国では利用できない支払い方法がある場合があります",
		p3: "FPMketsサービスへようこそ",
		btn: "即時口座開設取引",
	},
	header: {
		tip1: "レバレッジ取引は複雑な金融製品であり、急速に損失する高リスクがある。",
		btn1: "取引プラットフォーム",
		btn2: "ログアウト",
		btn3: "ログイン",
		BigUint64Array: "即時取引",
	},
	footer: {
		help: "連絡先",
		tip1: "私たちのコミュニティに注目",
		tip2: "FPMarketsにはTelegram公式群が設立されておらず、FPMarkets名義で設立されたTelegram群には詐欺の疑いがあることに注意してください。",
		tip3: "リスク警告：取引によってすべての資金が損失する可能性があります。場外派生品取引はすべての人に適していない。デルのサービスを使用する前に、デルの法律文書をよく読んで、取引前に関連するリスクを十分に理解してください。実際に関連する基礎資産を所有または所有しているわけではありません。",
		tip4: "FPMarketsは、購入、所有、または販売に関するアドバイス、推奨、または意見を提供しません。デルが提供するすべての製品は、グローバル資産をベースとした場外派生品です。FPMarketsが提供するすべてのサービスは、取引命令の実行にのみ基づいています。",
		tip5: "FPMarketsは、複数の企業が共同で使用するビジネスブランドで、以下の会社を通じて運営されています。",
		tip6: "MFMarkets International Ltdは、当サイトで説明されている、または使用可能な金融製品を提供する発行者です。FPMkets International Ltdは、モーリシャス金融サービス委員会（FSC）の権限を得て規制を受けている。ライセンス番号はGB 20025791、登録アドレスは：6 St Denis Street、1 st Floor River Court、Port Louis 11328、Mauritius",
		tip7: "FPMkets Global Pty Ltd登録番号はABN 90 149 011 361で、オーストラリア金融サービスライセンス（AFSL）番号は398528です。",
		tip8: "FPMarkets Holding Ltdはケイマン諸島金融管理局（CIMA）の権限を得て規制を受けており、SIBライセンス番号は1612446である。",
		tip9: "当サイトの情報は米国、カナダ、日本、ニュージーランドの住民を対象としていません。このサイトの情報は、その発行または使用によって現地の法律や法規に違反している国や司法管区の誰にも使用することはできません。英語はサービスに使用される主要な言語であり、すべての契約書類に法的効力がある言語であることに注意してください。その他の言語訳本は参考にしてください。英語とは異なる意味がある場合は、概して英語を基準としています。",
		tip10: "SSLセキュリティ通信暗号化。©  FPMarketsは著作権を所有し、すべての権利を保持しています。",
		link1: "プライバシーポリシー",
		link2: "製品開示ステートメント",
		link3: "クレームプロセス",
		link4: "リスク開示声明",
		link5: "顧客協定",
		toTop: "の頂部をおおう",
	},
	nav: {
		tit1: "製品",
		tit2: "取り引き",
		tit3: "投資学習",
		tit4: "私たちについて",
		nav1: "外国為替",
		nav2: "しすう",
		nav3: "商品",
		nav4: "ストック",
		nav5: "取引プラットフォーム",
		nav6: "取引戦略",
		nav7: "取引の観点",
		nav8: "財経カレンダー",
		nav9: "インスタントニュース",
		nav10: "リアルタイム見積もり",
		nav11: "情緒指数",
		nav12: "リスク管理",
		nav13: "契約細則",
		nav14: "投資の概要",
		nav15: "投資の知恵",
		nav16: "Academy",
		nav17: "FPMarketsについて",
		nav18: "賞と栄誉",
		nav19: "メディアセンター",
		nav20: "資金セキュリティ",
		nav21: "料金と料金",
		nav22: "Affiliates",
		nav23: "連絡先",
		nav24: "よくある質問",
		nav25: "ヘルプセンター",
	},

	city: {
		albania: "アルバニア",
		algeria: "アルジェリア",
		argentina: "アルゼンチン",
		armenia: "アルメニア",
		australia: "オーストラリア",
		pakistan: "パキスタン",
		austria: "オーストリア",
		bahrain: "オーストリア",
		belgium: "ベルギー",
		bosnia_and_Herzegovina: "ボスニア・ヘルツェゴビナ",
		brazil: "ブラジル",
		brunei: "ブルネイ",
		bulgaria: "ブルガリア",
		cambodia: "カンボジア",
		canada: "カナダ",
		cameroon: "カメルーン",
		chile: "チリ",
		colombia: "コロンビア",
		costa_Rica: "コスタリカ",
		croatia: "クロアチア",
		cyprus: "キプロス",
		czech_Republic: "チェコ共和国",
		denmark: "デンマーク",
		dominican_Republic: "ドミニカ共和国",
		egypt: "エジプト",
		estonia: "エストニア",
		ethiopia: "エチオピア",
		finland: "フィンランド",
		france: "フランス",
		georgia: "グルジア",
		germany: "ドイツ",
		ghana: "ガーナ",
		greece: "ギリシャ",
		guyana: "ガイアナ",
		honduras: "ホンジュラス",
		hong_Kong_China: "中国香港",
		hungary: "ハンガリー",
		iceland: "アイスランド",
		ireland: "アイルランド",
		italy: "イタリア",
		india: "インド",
		indonesia: "インドネシア",
		israel: "イスラエル",
		iran: "イラン",
		iraq: "イラク",
		japan: "日本",
		kazakstan: "カザフスタン",
		kenya: "ケニア",
		korea: "韓国",
		kuwait: "クウェート",
		kyrgyzstan: "キルギス",
		laos: "ラオス",
		latvia: "ラトビア",
		lithuania: "リトアニア",
		luxembourg: "ルクセンブルク",
		macao_China: "中国マカオ",
		macedonia: "マケドニア",
		malaysia: "マレーシア",
		malta: "マルタ",
		mexico: "メキシコ",
		moldova: "モルドバ",
		monaco: "モナコ",
		mongolia: "モンゴル",
		montenegro: "モンテネグロ共和国",
		morocco: "モロッコ",
		myanmar: "ミャンマー",
		netherlands: "オランダ",
		new_Zealand: "ニュージーランド",
		nepal: "ネパール",
		nigeria: "ナイジェリア",
		norway: "ノルウェー",
		oman: "オマーン",
		palestine: "パレスチナ",
		panama: "パナマ",
		paraguay: "パラグアイ",
		peru: "ペルー",
		philippines: "フィリピン",
		poland: "ポーランド",
		portugal: "ポルトガル",
		puerto_Rico: "プエルトリコ",
		qatar: "カタール",
		romania: "ルーマニア",
		russia: "ロシア",
		republic_of_Trinidad_and_Tobago: "トリニダード・トバゴ共和国",
		rwanda: "ルワンダ",
		saudi_Arabia: "サウジアラビア",
		serbia: "セルビア",
		singapore: "シンガポール",
		slovakia: "スロバキア",
		slovenia: "スロベニア",
		south_Africa: "南アフリカ共和国",
		spain: "スペイン",
		sri_Lanka: "スリランカ",
		sweden: "スウェーデン",
		switzerland: "スイス",
		taiwan_China: "中国台湾",
		tajikistan: "タジキスタン",
		tanzania: "タンザニア",
		thailand: "タイ",
		turkey: "トルコ",
		turkmenistan: "トルクメニスタン",
		ukraine: "ウクライナ",
		united_Arab_Emirates: "アラブ首長国連邦",
		united_Kingdom: "英国",
		united_States: "アメリカ",
		uzbekistan: "ウズベキスタン",
		venezuela: "ベネズエラ",
		vietnam: "ベトナム",
		afghanistan: "アフガニスタン",
		angola: "アンゴラ",
		azerbaijan: "アゼルバイジャン",
		bangladesh: "バングラデシュ",
		belarus: "ベラルーシ",
		belize: "ベリーズ",
		benin: "ベニン",
		bhutan: "ブータン",
		bolivia: "ボリビア",
		botswana: "ボツワナ",
		british_Virgin_Islands: "イギリス領ヴァージン諸島",
		burkina_Faso: "ブルキナファソ",
		burundi: "ブルンジ",
		cape_Verde: "カーボベルデ角",
		cayman_Islands: "ケイマン諸島",
		central_African_Republic: "中央アフリカ共和国",
		chad: "チャド",
		comoros: "コモロ",
		the_Republic_of_the_Congo: "コンゴ（布）",
		democratic_Republic_of_the_Congo: "コンゴ（金）",
		djibouti: "ジブチ",
		ecuador: "エクアドル",
		el_Salvador: "エルサルバドル",
		equatorial_Guinea: "赤道ギニア",
		eritrea: "エリトリア",
		fiji: "フィジー",
		gabon: "ガボン",
		gambia: "ガンビア",
		greenland: "グリーンランド",
		guatemala: "グアテマラ",
		guinea: "ギニア",
		haiti: "ハイチ",
		isle_of_Man: "マーン島",
		cote_d_Ivoire: "コートジボワール",
		jamaica: "ジャマイカ",
		jordan: "ヨルダン",
		lebanon: "レバノン",
		lesotho: "レソト",
		liberia: "リベリア",
		libya: "リビア",
		madagascar: "マダガスカル",
		malawi: "マラウイ",
		maldives: "モルディブ",
		mali: "マリ",
		mauritania: "モーリタニア",
		mauritius: "モーリシャス",
		mozambique: "モザンビーク",
		namibia: "ナミビア",
		nicaragua: "ニカラグア",
		republic_of_Niger: "ニジェール",
		north_Korea: "朝鲜",
		reunion: "レユニオン",
		san_Marino: "サンマリノ",
		senegal: "セネガル",
		sierra_Leone: "シエラレオネ",
		somalia: "ソマリア",
		sudan: "スーダン",
		suriname: "スリナム",
		eswatini: "スワジランド",
		syria: "シリア",
		togo: "トーゴ",
		tonga: "トンガ",
		tunisia: "チュニジア",
		united_States_Virgin_Islands: "アメリカ領ヴァージン諸島",
		uganda: "ウガンダ",
		uruguay: "ウルグアイ",
		vatican: "バチカン",
		yemen: "イエメン",
		yugoslavia: "ユーゴスラビア",
		zambia: "ザンビア",
		zimbabwe: "ジンバブエ",
		china: "中国",
	}
}
